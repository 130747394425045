.ai-schema-container {
    padding-top: 80px;
    padding-bottom: 150px;

    @media (max-width: 700px) {
    padding-bottom: 0px;
    }
}

.ai-schema-title {
            font-weight: 600;
                font-size: 60px;
                line-height: 64px;
                text-align: center;
                margin: auto;
                margin-bottom: 13px;

                @media (max-width: 400px) {
                    max-width: 250px;
                &::after {
                        content: url('../images/styles/mob-arm.png');
                        vertical-align: sub;
                        margin-left: 15px;
                    }
                }

                @media (min-width: 401px) {
                    &::after {
                            content: url('../images/styles/benefit-title.png');
                            vertical-align: sub;
                            margin-left: 15px;
                        }
                }
                    
            
                @media (max-width: 1000px) {
                    font-size: 40px;
                    width: 95%;
                }
            
                @media (max-width: 400px) {
                    font-size: 30px;
                    width: 90%;
                    line-height: 38px;
                }
}

.ua-schema-title {
    @media (max-width: 400px) {
            max-width: 320px;
        }
}

.ai-schema-description {
text-align: center;
margin-bottom: 70px;
font-weight: 400;
font-size: 18px;
line-height: 24px;
color: #525C7A;

@media (max-width: 400px) {
        width: 90%;
        font-size: 16px;
        margin: 0 auto;
    }
}

        .ai-schema-image {
            @media (max-width: 700px) {
                display: none;
            }
        }
    
        .mobile-ai-schema-image {
            margin: 0 auto;
    
            @media (min-width: 701px) {
                display: none;
            }
        }


        .ai-watch-wideo-button {
                  display: flex;
                  background: #2F3546;
                  border-radius: 2000px;
                  border: none;
                  padding: 10px 24px;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 20px;
                  text-align: center;
                  color: #FFFFFF;
                  justify-content: center;
                  max-width: 400px;
                  margin: 32px auto 0 auto;

                  @media (max-width: 701px) {
                    margin: 0 auto;
                  }
        
                  .main-menu-icon {
                      vertical-align: bottom;
                      fill: white;
                  }
        
                  p {
                      margin-left: 5px;
                  }
        
              }