.communication-container {
    padding-top: 110px;
    padding-bottom: 150px;

    @media (max-width: 700px) {
        padding: 0;
    }

    .background-communication-title {
        position: relative;
        background-image: url('../images/background/communication-bg.png');
        background-size: 1100px 200px;
        background-repeat: no-repeat;
       max-width: 1100px;
       height: 200px; 
       margin: 0 auto;
       display: flex;


       @media (max-width: 1000px) {
        background-size: 700px 200px;
        }

        @media (max-width: 650px) {
        background-size: 100% 300px;
        background-position: center;
        height: auto;
        }
    
       .communication-title {
        font-weight: 600;
        font-size: 60px;
        line-height: 64px;
        text-align: center;
        max-width: 875px;
        margin: auto;

        @media (max-width: 1000px) {
            font-size: 40px;
            width: 80%;
        }
        @media (max-width: 400px) {
            width: 100%;
        }
    
        // &::before {
        //     content: url('../images/social/telegram.png');
        //     position: absolute;
        //     top: 0;
        //     left: 0;

        //     @media (max-width: 900px) {
        //         transform: scale(0.5); 
        //     }
        //     @media (max-width: 400px) {
        //         z-index: -1;
        //     }
        // }
        // &::after {
        //     content: url('../images/social/viber.png');
        //     position: absolute;
        //     bottom: 0;
        //     right: 0;

        //     @media (max-width: 900px) {
        //         transform: scale(0.5);
        //     }
        //     @media (max-width: 400px) {
        //         z-index: -1;
        //         bottom: 10px;
        //     }
        // }
       }
    }
    .communication-image {
        @media (max-width: 700px) {
            display: none;
        }
    }

    .mobile-communication-image {
        margin: 0 auto;
        @media (min-width: 701px) {
            display: none;
        }
    }
}



