.container {
    max-width: 1400px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    overflow: hidden;

    @media (max-width: 500px) {
      padding-left: 0px;
    padding-right: 0px;
    }
  }