*, :before, :after {
  box-sizing: border-box;
}

html {
  tab-size: 4;
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
  font-family: system-ui, AppleSystem, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, AppleSystem, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

hr {
  height: 0;
  color: inherit;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

h1, h2, h3, h4, h5, h6, p {
  margin-top: 0;
  margin-bottom: 0;
}

a {
  text-decoration: none;
}

ul, ol {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
}

button {
  cursor: pointer;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.link {
  color: #438df6;
  text-decoration: none;
}

*, :before, :after {
  box-sizing: border-box;
}

body {
  color: #2f3546;
  letter-spacing: -.3px;
  font-family: Inter, sans-serif;
  font-style: normal;
}

.disabled {
  pointer-events: none;
  cursor: default;
  color: #888 !important;
  background-color: #d9d9d9 !important;
}

html {
  scroll-behavior: smooth;
}

.black-hov {
  transition: all .5s;
}

.black-hov:hover {
  color: #fff !important;
  background: #438df6 !important;
  box-shadow: 0 0 1px 3px rgba(67, 141, 246, .6), 0 0 2px 1px rgba(0, 0, 0, .08), 0 0 2px rgba(0, 0, 0, .1), 0 1px 2px rgba(0, 0, 0, .15) !important;
}

.white-hov, .white-hov p {
  transition: all .5s;
}

.white-hov:hover {
  color: #438df6 !important;
  fill: #438df6 !important;
  box-shadow: 0 0 1px 3px rgba(67, 141, 246, .6), 0 0 2px 1px rgba(0, 0, 0, .08), 0 0 2px rgba(0, 0, 0, .1), 0 1px 2px rgba(0, 0, 0, .15) !important;
}

.white-hov:hover p {
  color: #438df6 !important;
}

.container {
  max-width: 1400px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  overflow: hidden;
}

@media (max-width: 500px) {
  .container {
    padding-left: 0;
    padding-right: 0;
  }
}

.header-elements-container {
  height: 92px;
  justify-content: space-between;
  display: flex;
}

@media (max-width: 500px) {
  .header-elements-container {
    padding: 0 10px;
  }
}

.header {
  background-image: url("main-bg.759b341b.png");
  background-repeat: no-repeat;
  background-size: 100% 875px;
}

.tariff-header {
  background-image: url("main-bg.759b341b.png");
  background-repeat: no-repeat;
  background-size: 100% 875px;
}

.opportunities-header {
  background-image: url("main-bg.759b341b.png");
  background-repeat: no-repeat;
  background-size: 100% 705px;
}

.blog-header {
  background-image: url("main-bg.759b341b.png");
  background-repeat: no-repeat;
  background-size: 100% 608px;
}

.one-page-blog-header {
  background-image: url("main-bg.759b341b.png");
  background-repeat: no-repeat;
  background-size: 100% 455px;
}

.contact-header {
  background-image: url("main-bg.759b341b.png");
  background-repeat: no-repeat;
  background-size: 100% 617px;
}

.about-us-header {
  background-image: url("main-bg.759b341b.png");
  background-repeat: no-repeat;
  background-size: 100% 455px;
}

.ai-header {
  background-image: url("main-bg.759b341b.png");
  background-repeat: no-repeat;
  background-size: 100% 875px;
}

.container {
  transition: all .5s;
}

.container.active {
  background-color: #fff;
}

.left-side-header, .right-side-header {
  gap: 24px;
  margin: auto 0;
  display: flex;
  position: relative;
}

.header-navigation {
  gap: 16px;
  display: flex;
}

.header-navigation .navigation-item {
  margin: auto 0;
}

.header-navigation .navigation-item:hover .navigation-link {
  color: #2f3546;
  font-weight: 600;
}

.header-navigation .navigation-item .navigation-link {
  color: #525c7a;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  transition: all .5s;
}

.header-navigation .navigation-item .active-page {
  color: #2f3546;
  font-weight: 600;
}

@media (max-width: 1010px) {
  .header-navigation {
    display: none;
  }
}

.language-menu {
  color: #516785;
  cursor: pointer;
  height: 40px;
  background: rgba(255, 255, 255, .56);
  border-radius: 12px;
  gap: 3px;
  padding: 10px 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  position: absolute;
  top: -10px;
  left: -95px;
  overflow: hidden;
}

.language-menu #current-lang {
  transition: all .5s;
}

.language-menu:hover #current-lang {
  color: #438df6;
}

.language-menu:hover .language-menu-icon {
  stroke: #438df6;
}

.language-menu .other-lang {
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

@media (max-width: 1010px) {
  .language-menu {
    display: none;
  }
}

.language-menu .language-menu-icon {
  vertical-align: bottom;
  fill: rgba(0, 0, 0, 0);
  stroke: #516785;
  transition: all .5s;
}

.reverce {
  transform: rotate(180deg);
}

.open-lang {
  height: 70px;
  transition: all .5s;
}

.open-lang .other-lang {
  opacity: 1;
  cursor: pointer;
  pointer-events: all;
  color: #525c7a;
  margin-top: 30px;
  transition: all .3s;
}

.open-lang .other-lang:hover {
  color: #438df6;
}

.registration-buttons {
  margin: auto 0;
}

@media (max-width: 1010px) {
  .registration-buttons {
    display: none;
  }
}

.come-in-button {
  color: #2f3546;
  margin-right: 24px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  transition: all .3s;
}

.come-in-button:hover {
  color: #438df6;
}

.registration-button {
  color: #fff;
  background: #2f3546;
  border-radius: 2000px;
  padding: 10px 24px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .08), 0 2px 4px rgba(0, 0, 0, .12);
}

.demo {
  margin: auto 0;
}

@media (min-width: 1010px) {
  .demo {
    display: none;
  }
}

.menu-icon {
  width: 50px;
  height: 50px;
  cursor: pointer;
  position: relative;
}

.menu-icon .menu-icon__cheeckbox {
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 2;
  -webkit-touch-callout: none;
  opacity: 0;
  display: block;
  position: relative;
}

.menu-icon div {
  width: 22px;
  height: 12px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.menu-icon span {
  width: 100%;
  height: 2px;
  background-color: #516785;
  border-radius: 2px;
  transition: all .2s cubic-bezier(.1, .82, .76, .965);
  display: block;
  position: absolute;
}

.menu-icon span:first-of-type {
  width: 30px;
  top: 0;
}

.menu-icon span:last-of-type {
  width: 20px;
  height: 2px;
  bottom: 0;
  right: -7px;
}

.menu-icon-active span:first-of-type {
  width: 22px;
  background-color: #4086f5;
  top: 6px;
  transform: rotate(45deg);
}

.menu-icon-active span:last-of-type {
  width: 22px;
  background-color: #4086f5;
  bottom: 4px;
  left: 0;
  transform: rotate(-45deg);
}

.menu-icon-active.active:hover span:first-of-type, .menu-icon-active.active:hover span:last-of-type {
  width: 22px;
}

.mobile-navigation {
  width: 100%;
  height: auto;
  z-index: 100;
  background-color: #fff;
  padding: 16px;
  transition: transform .5s;
  position: absolute;
  top: 92px;
  left: 0;
  transform: translateX(-150%);
}

.mobile-navigation .mobile-navigation-list .mobile-navigation-item {
  height: 68px;
  border-bottom: 1px solid #ced2de;
}

.mobile-navigation .mobile-navigation-list .mobile-navigation-item a {
  color: #525c7a;
  font-size: 16px;
  font-weight: 500;
  line-height: 65px;
  text-decoration: none;
}

.mobile-navigation .mobile-language-menu {
  justify-content: center;
  margin-top: 50px;
  display: flex;
}

.mobile-navigation .mobile-language-menu .language-menu-mobile-button {
  color: #525c7a;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  padding: 0 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  position: relative;
}

.mobile-navigation .mobile-language-menu .language-menu-mobile-button:nth-child(1) {
  border-right: 2px solid #525c7a;
}

.mobile-navigation .mobile-language-menu .language-menu-mobile-button.active {
  color: #438df6;
}

.menu_active {
  transform: translateX(0%);
}

.main-section {
  padding-top: 70px;
}

@media (max-width: 800px) {
  .main-section {
    padding-top: 48px;
  }
}

@media (max-width: 400px) {
  .main-section {
    padding-top: 20px;
  }
}

.main-section .main-section-title {
  text-align: center;
  text-shadow: 0 4px 4px rgba(0, 0, 0, .25);
  max-width: 740px;
  margin: 0 auto;
  font-size: 60px;
  font-weight: 600;
  line-height: 64px;
}

@media (max-width: 800px) {
  .main-section .main-section-title {
    text-shadow: none;
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
  }
}

.main-section .main-section-text {
  text-align: center;
  max-width: 390px;
  margin: 15px auto 40px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

@media (max-width: 800px) {
  .main-section .main-section-text {
    font-size: 16px;
  }
}

.main-section .main-buttons-container {
  justify-content: center;
  gap: 16px;
  display: flex;
}

.main-section .main-buttons-container .start-button {
  text-align: center;
  color: #fff;
  background: #2f3546;
  border: none;
  border-radius: 2000px;
  padding: 10px 24px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .08), 0 2px 4px rgba(0, 0, 0, .12);
}

@media (max-width: 500px) {
  .main-section .main-buttons-container .start-button {
    width: 90%;
    margin-bottom: 16px;
    margin-right: 0;
  }
}

.main-section .main-buttons-container .watch-wideo-button {
  text-align: center;
  color: #2f3546;
  background: #fff;
  border: none;
  border-radius: 2000px;
  justify-content: center;
  padding: 10px 24px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  display: flex;
}

@media (max-width: 500px) {
  .main-section .main-buttons-container .watch-wideo-button {
    width: 90%;
  }
}

.main-section .main-buttons-container .watch-wideo-button .main-menu-icon {
  vertical-align: bottom;
}

@media (max-width: 500px) {
  .main-section .main-buttons-container {
    flex-direction: column;
    align-items: center;
    display: flex;
  }
}

.main-section .main-section-image {
  max-width: 1300px;
  margin: 10px auto 0;
}

@media (max-width: 1300px) {
  .main-section .main-section-image {
    width: 100%;
    margin: 30px auto 0;
  }
}

@media (max-width: 500px) {
  .main-section .main-section-image {
    display: none;
  }
}

.mobile-main-section-image {
  margin-top: 41px;
}

@media (min-width: 501px) {
  .mobile-main-section-image {
    display: none;
  }
}

.creatives-cection {
  padding-top: 100px;
  padding-bottom: 50px;
}

.creatives-cection .creatives-title {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
}

@media (max-width: 500px) {
  .creatives-cection .creatives-title {
    display: none;
  }
}

.creatives-cection .creatives-companies-list {
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
  margin-top: 40px;
  display: flex;
}

@media (max-width: 400px) {
  .creatives-cection .creatives-companies-list {
    gap: 0;
  }
}

.creatives-cection .creatives-companies-list .icon {
  width: 120px;
}

@media (max-width: 1200px) {
  .creatives-cection .creatives-companies-list .icon {
    width: 150px;
  }
}

@media (max-width: 400px) {
  .creatives-cection .creatives-companies-list .icon {
    width: 120px;
    margin-bottom: 24px;
  }
}

.communication-container {
  padding-top: 110px;
  padding-bottom: 150px;
}

@media (max-width: 700px) {
  .communication-container {
    padding: 0;
  }
}

.communication-container .background-communication-title {
  max-width: 1100px;
  height: 200px;
  background-image: url("communication-bg.6be7b627.png");
  background-repeat: no-repeat;
  background-size: 1100px 200px;
  margin: 0 auto;
  display: flex;
  position: relative;
}

@media (max-width: 1000px) {
  .communication-container .background-communication-title {
    background-size: 700px 200px;
  }
}

@media (max-width: 650px) {
  .communication-container .background-communication-title {
    height: auto;
    background-position: center;
    background-size: 100% 300px;
  }
}

.communication-container .background-communication-title .communication-title {
  text-align: center;
  max-width: 875px;
  margin: auto;
  font-size: 60px;
  font-weight: 600;
  line-height: 64px;
}

@media (max-width: 1000px) {
  .communication-container .background-communication-title .communication-title {
    width: 80%;
    font-size: 40px;
  }
}

@media (max-width: 400px) {
  .communication-container .background-communication-title .communication-title {
    width: 100%;
  }
}

@media (max-width: 700px) {
  .communication-container .communication-image {
    display: none;
  }
}

.communication-container .mobile-communication-image {
  margin: 0 auto;
}

@media (min-width: 701px) {
  .communication-container .mobile-communication-image {
    display: none;
  }
}

.benefit-section {
  background-color: #f8f9fc;
  padding-bottom: 64px;
  position: relative;
}

.benefit-section .benefits-container {
  height: 1150px;
  transition: transform .5s;
  position: relative;
  overflow: hidden;
}

@media (max-width: 1000px) {
  .benefit-section .benefits-container {
    height: 900px;
  }
}

@media (max-width: 1000px) {
  .benefit-section .benefits-container {
    height: 900px;
  }
}

@media (max-width: 700px) {
  .benefit-section .benefits-container {
    height: 2000px;
  }
}

@media (max-width: 600px) {
  .benefit-section .benefits-container {
    height: 1900px;
  }
}

@media (max-width: 500px) {
  .benefit-section .benefits-container {
    height: 1800px;
  }
}

@media (max-width: 400px) {
  .benefit-section .benefits-container {
    height: 1700px;
  }
}

.benefit-section .mob-first-benefits-container {
  height: 1090px;
  transition: transform .5s;
  position: relative;
  overflow: hidden;
}

@media (max-width: 700px) {
  .benefit-section .mob-first-benefits-container {
    height: 1400px;
  }
}

@media (max-width: 500px) {
  .benefit-section .mob-first-benefits-container {
    height: 1300px;
  }
}

.benefit-title-container {
  padding-top: 64px;
}

.benefit-title-container .benefit-bg {
  height: 250px;
  width: 800px;
  position: absolute;
  top: 0;
}

@media (max-width: 800px) {
  .benefit-title-container .benefit-bg {
    width: 80%;
  }
}

.benefit-title-container .benefit-title {
  text-align: center;
  color: #2f3546;
  max-width: 870px;
  margin: 0 auto;
  font-size: 60px;
  font-weight: 600;
  line-height: 64px;
}

.benefit-title-container .benefit-title:after {
  content: url("benefit-title.a623f40c.png");
  vertical-align: sub;
  margin-left: 15px;
}

@media (max-width: 600px) {
  .benefit-title-container .benefit-title {
    font-size: 40px;
  }
}

.benefit-image {
  margin-left: 50px;
}

.benefit-image-second {
  width: 90%;
  margin: 0 auto 0 90px;
}

.benefit-image-third {
  width: 80%;
  margin: 0 auto 0 100px;
}

.benefits-opportunities-list {
  justify-content: center;
  padding-top: 64px;
  padding-bottom: 64px;
  transition: transform .5s;
  display: flex;
}

@media (max-width: 700px) {
  .benefits-opportunities-list {
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding-top: 1350px;
  }
}

@media (max-width: 600px) {
  .benefits-opportunities-list {
    padding-top: 1300px;
  }
}

@media (max-width: 500px) {
  .benefits-opportunities-list {
    padding-top: 1120px;
  }
}

@media (max-width: 400px) {
  .benefits-opportunities-list {
    padding-top: 950px;
  }
}

.mob-benefits-opportunities-list {
  justify-content: center;
  padding-top: 64px;
  padding-bottom: 64px;
  transition: transform .5s;
  display: flex;
}

@media (max-width: 700px) {
  .mob-benefits-opportunities-list {
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding-top: 700px;
  }
}

@media (max-width: 500px) {
  .mob-benefits-opportunities-list {
    padding-top: 600px;
  }
}

.benefits-opportunities-item {
  width: 33.3333%;
  text-align: center;
  cursor: pointer;
  color: #2f3546;
  border-top: 3px solid #edeef3;
  padding: 16px 24px;
  transition: all .5s;
}

.benefits-opportunities-item:hover .benefits-opportunities-text, .benefits-opportunities-item:hover .text-buttons-hover {
  color: #438df6;
}

.benefits-opportunities-item:hover .icon-opportunities {
  fill: #438df6;
  margin-bottom: 16px;
}

@media (max-width: 700px) {
  .benefits-opportunities-item {
    width: 100%;
  }
}

.benefits-opportunities-item .icon-opportunities {
  fill: #2f3546;
  margin-bottom: 16px;
  transition: all .5s;
}

.benefits-opportunities-item .benefits-opportunities-text {
  text-align: center;
  max-width: 365px;
  color: #2f3546;
  margin: 0 auto;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  transition: all .5s;
}

.benefits-opportunities-item span {
  font-size: 18px;
  font-weight: 400;
  transition: all .5s;
}

.active {
  border-top: 3px solid #438df6;
}

.active .benefits-opportunities-text {
  color: #438df6;
}

.active .icon-opportunities {
  fill: #438df6;
  margin-bottom: 16px;
  transition: all .5s;
}

.active span {
  color: #438df6;
}

.first-benefit {
  transition: transform 1s;
  position: absolute;
  transform: translateX(0);
}

@media (max-width: 700px) {
  .first-benefit {
    display: none;
  }
}

.second-benefit {
  transition: transform 1s;
  position: absolute;
  transform: translateX(150%);
}

@media (max-width: 700px) {
  .second-benefit {
    display: none;
  }
}

.third-benefit {
  transition: transform 1s;
  position: absolute;
  transform: translateX(300%);
}

@media (max-width: 700px) {
  .third-benefit {
    display: none;
  }
}

.mob-first-benefit {
  transition: transform 1s;
  position: absolute;
  transform: translateX(0);
}

@media (min-width: 701px) {
  .mob-first-benefit {
    display: none;
  }
}

.mob-second-benefit {
  transition: transform 1s;
  position: absolute;
  transform: translateX(150%);
}

@media (min-width: 701px) {
  .mob-second-benefit {
    display: none;
  }
}

.mob-third-benefit {
  transition: transform 1s;
  position: absolute;
  transform: translateX(300%);
}

@media (min-width: 701px) {
  .mob-third-benefit {
    display: none;
  }
}

.first-active {
  transform: translateX(0) !important;
}

.second-active, .third-active {
  transform: translateX(150%) !important;
}

.benefits-info {
  max-width: 780px;
  text-align: center;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.benefit-mobile-image {
  margin-top: 80px;
}

.benefits-mobile-info {
  margin-top: 35px;
}

.commerce-info-containers {
  padding: 64px 104px;
}

@media (max-width: 1400px) {
  .commerce-info-containers {
    padding: 64px 0;
  }
}

@media (max-width: 850px) {
  .commerce-info-containers .automatisation-commerce-small-container .commerce-image-container {
    order: 1;
  }

  .commerce-info-containers .automatisation-commerce-small-container .text-automatisation-container {
    order: 0;
  }
}

.commerce-info-containers .commerce-small-container {
  height: auto;
  width: 100%;
  display: flex;
}

.commerce-info-containers .commerce-small-container:not(:last-child) {
  margin-bottom: 80px;
}

@media (max-width: 1100px) {
  .commerce-info-containers .commerce-small-container {
    height: auto;
  }
}

@media (max-width: 850px) {
  .commerce-info-containers .commerce-small-container {
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 851px) {
  .commerce-info-containers .commerce-small-container .mobile-container {
    display: none;
  }
}

.commerce-info-containers .commerce-small-container .text-container {
  width: 50%;
  background-size: 100%;
}

@media (max-width: 850px) {
  .commerce-info-containers .commerce-small-container .text-container {
    width: 100%;
    flex-direction: column;
    margin-bottom: 32px;
    display: flex;
  }
}

.commerce-info-containers .commerce-small-container .text-container .commerce-section-title {
  max-width: 100%;
  margin-bottom: 16px;
  font-size: 36px;
  font-weight: 600;
  line-height: 42px;
}

@media (max-width: 1400px) {
  .commerce-info-containers .commerce-small-container .text-container .commerce-section-title {
    font-size: 30px;
  }
}

@media (max-width: 850px) {
  .commerce-info-containers .commerce-small-container .text-container .commerce-section-title {
    text-align: center;
    margin: 0 auto;
  }
}

.commerce-info-containers .commerce-small-container .text-container .commerce-section-text {
  max-width: 400px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

@media (max-width: 1400px) {
  .commerce-info-containers .commerce-small-container .text-container .commerce-section-text {
    font-size: 16px;
  }
}

@media (max-width: 850px) {
  .commerce-info-containers .commerce-small-container .text-container .commerce-section-text {
    text-align: center;
    margin: 0 auto;
  }
}

.commerce-info-containers .commerce-small-container .text-container .icon-commerce {
  margin: 0 auto;
}

@media (max-width: 1100px) {
  .commerce-info-containers .commerce-small-container .text-container .icon-commerce {
    margin-bottom: 0;
  }
}

@media (max-width: 850px) {
  .commerce-info-containers .commerce-small-container .text-container .icon-commerce {
    margin-bottom: 15px;
  }
}

@media (max-width: 850px) {
  .commerce-info-containers .commerce-small-container .desctop-container {
    display: none;
  }
}

.commerce-info-containers .commerce-small-container .text-commerce-container {
  background-image: url("e-commerce-bg.6f3e935a.png");
  background-repeat: no-repeat;
  padding: 37px 80px 99px 50px;
}

@media (max-width: 1100px) {
  .commerce-info-containers .commerce-small-container .text-commerce-container {
    padding: 10px;
  }
}

@media (max-width: 850px) {
  .commerce-info-containers .commerce-small-container .text-commerce-container {
    background-position: center;
    background-size: 600px 400px;
  }
}

.commerce-info-containers .commerce-small-container .text-automatisation-container {
  background-image: url("api-bg.c8199dfd.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 650px 500px;
  padding: 15px 40px 0 100px;
}

.commerce-info-containers .commerce-small-container .text-automatisation-container .commerce-section-title {
  max-width: 315px;
}

.commerce-info-containers .commerce-small-container .text-automatisation-container .commerce-section-text {
  max-width: 350px;
}

@media (max-width: 1100px) {
  .commerce-info-containers .commerce-small-container .text-automatisation-container {
    background-size: 550px 500px;
    padding: 10px;
  }
}

@media (max-width: 850px) {
  .commerce-info-containers .commerce-small-container .text-automatisation-container {
    background-size: 600px 400px;
  }
}

.commerce-info-containers .commerce-small-container .text-automatisation-container .show-more-button {
  color: #438df6;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  display: block;
}

.commerce-info-containers .commerce-small-container .text-automatisation-container .show-more-button:after {
  content: "➜";
  color: #438df6;
  vertical-align: -1px;
  opacity: 0;
  margin-left: -2px;
  font-size: 15px;
  font-weight: 600;
  line-height: 15px;
  transition: all .2s;
}

.commerce-info-containers .commerce-small-container .text-automatisation-container .show-more-button:hover:after {
  opacity: 1;
  margin-left: 5px;
}

.commerce-info-containers .commerce-small-container .text-automatisation-container .automatisation-title {
  max-width: 415px;
}

@media (max-width: 1100px) {
  .commerce-info-containers .commerce-small-container .text-automatisation-container .automatisation-title, .commerce-info-containers .commerce-small-container .text-automatisation-container .automatisation-text {
    max-width: 515px;
  }
}

.commerce-info-containers .commerce-small-container .text-api-container {
  background-image: url("api-bg.c8199dfd.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 650px 500px;
  padding: 69px 99px 110px 146px;
}

@media (max-width: 1100px) {
  .commerce-info-containers .commerce-small-container .text-api-container {
    background-size: 550px 500px;
    padding: 10px;
  }
}

@media (max-width: 850px) {
  .commerce-info-containers .commerce-small-container .text-api-container {
    background-size: 600px 400px;
  }
}

.commerce-info-containers .commerce-small-container .commerce-image-container {
  width: 50%;
}

@media (max-width: 850px) {
  .commerce-info-containers .commerce-small-container .commerce-image-container {
    width: 80%;
  }
}

@media (max-width: 500px) {
  .commerce-info-containers .commerce-small-container .commerce-image-container {
    width: 100%;
  }
}

@media (max-width: 550px) {
  .commerce-info-containers .commerce-small-container .commerce-image-container .automatisation-image {
    display: none;
  }
}

@media (min-width: 551px) {
  .commerce-info-containers .commerce-small-container .commerce-image-container .automatisation-image-mobile {
    display: none;
  }
}

.mobile-app-section-container {
  padding-bottom: 50px;
}

.mobile-app-section-container .mobile-app-main-flex-container {
  background: #f8f9fc;
  border-radius: 24px;
  display: flex;
}

@media (max-width: 1000px) {
  .mobile-app-section-container .mobile-app-main-flex-container {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}

.mobile-app-section-container .mobile-app-main-flex-container .mobile-app-text-container {
  width: 50%;
  padding: 100px 64px 0 32px;
}

@media (max-width: 1100px) {
  .mobile-app-section-container .mobile-app-main-flex-container .mobile-app-text-container {
    padding: 50px 64px 0 32px;
  }
}

@media (max-width: 1000px) {
  .mobile-app-section-container .mobile-app-main-flex-container .mobile-app-text-container {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .mobile-app-section-container .mobile-app-main-flex-container .mobile-app-text-container {
    padding: 0 20px;
  }
}

.mobile-app-section-container .mobile-app-main-flex-container .mobile-app-text-container .mobile-app-title {
  letter-spacing: -.02em;
  color: #101828;
  margin-bottom: 8px;
  font-size: 48px;
  font-weight: 600;
  line-height: 60px;
}

.mobile-app-section-container .mobile-app-main-flex-container .mobile-app-text-container .mobile-app-text {
  color: #475467;
  margin-bottom: 40px;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}

.mobile-app-section-container .mobile-app-main-flex-container .mobile-app-text-container .able-soon-text {
  margin: 20px 0;
}

.mobile-app-section-container .mobile-app-main-flex-container .mobile-app-text-container .mobile-app__mobile-links {
  gap: 12px;
  display: flex;
}

@media (max-width: 500px) {
  .mobile-app-section-container .mobile-app-main-flex-container .mobile-app-text-container .mobile-app__mobile-links {
    justify-content: center;
  }
}

.mobile-app-section-container .mobile-app-main-flex-container .mobile-app-text-container .mobile-app__mobile-links .download-mobile-footer-links {
  width: 160px;
  text-align: center;
  color: #fff;
  background: #2f3546;
  border-radius: 2000px;
  padding: 10px 24px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .12), 0 0 2px rgba(0, 0, 0, .12);
}

@media (max-width: 500px) {
  .mobile-app-section-container .mobile-app-main-flex-container .mobile-app-text-container .mobile-app__mobile-links .download-mobile-footer-links {
    width: 180px;
  }
}

.mobile-app-section-container .mobile-app-main-flex-container .mobile-app-text-container .mobile-app__mobile-links .download-mobile-footer-links .icon-footer-mobile-download-links {
  vertical-align: -6px;
  margin-right: 8px;
}

.mobile-app-section-container .mobile-app-main-flex-container .mobile-app-image-container {
  width: 50%;
}

@media (max-width: 1000px) {
  .mobile-app-section-container .mobile-app-main-flex-container .mobile-app-image-container {
    width: 80%;
  }
}

@media (max-width: 500px) {
  .mobile-app-section-container .mobile-app-main-flex-container .mobile-app-image-container .mobile-app-image-desctop {
    display: none;
  }
}

@media (min-width: 500px) {
  .mobile-app-section-container .mobile-app-main-flex-container .mobile-app-image-container .mobile-app-image-mob {
    display: none;
  }
}

.questions-section-container {
  height: 600px;
  margin-bottom: 60px;
  display: flex;
}

@media (max-width: 1250px) {
  .questions-section-container {
    height: auto;
    flex-direction: column;
    align-items: center;
  }
}

.questions-section-container .questions-title-container {
  width: 50%;
  background-image: url("question-bg.a4e62e06.png");
  background-repeat: no-repeat;
  background-size: 650px 450px;
  padding-top: 90px;
}

@media (max-width: 1250px) {
  .questions-section-container .questions-title-container {
    width: 100%;
  }
}

.questions-section-container .questions-title-container .questions-title {
  max-width: 480px;
  margin: 0 auto 32px;
  font-size: 60px;
  font-weight: 600;
  line-height: 64px;
}

@media (max-width: 1250px) {
  .questions-section-container .questions-title-container .questions-title {
    max-width: 680px;
    text-align: center;
  }
}

@media (max-width: 600px) {
  .questions-section-container .questions-title-container .questions-title {
    font-size: 40px;
  }
}

.questions-section-container .questions-info-container {
  width: 50%;
  padding-top: 90px;
}

@media (max-width: 1250px) {
  .questions-section-container .questions-info-container {
    width: 100%;
  }
}

.questions-section-container .questions-info-container .questions-list .questions-items {
  height: 72px;
  cursor: pointer;
  border-bottom: 1px solid #ced2de;
  transition: all .2s;
}

.questions-section-container .questions-info-container .questions-list .questions-items:hover {
  background: #f9fafb;
  border-radius: 12px;
  padding: 10px;
}

.questions-section-container .questions-info-container .questions-list .questions-items .item-view-container {
  height: 100%;
  transition: height .5s;
  display: flex;
}

.questions-section-container .questions-info-container .questions-list .questions-items .item-view-container .questions-items-text {
  margin: auto 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 38px;
}

@media (max-width: 600px) {
  .questions-section-container .questions-info-container .questions-list .questions-items .item-view-container .questions-items-text {
    font-size: 16px;
  }
}

.questions-section-container .questions-info-container .questions-list .questions-items .item-view-container .is-open-info {
  color: rgba(82, 92, 122, .75);
  margin: auto 20px auto 0;
  font-size: 30px;
  font-weight: 400;
}

.questions-section-container .questions-info-container .questions-list .hidden-question-answer {
  display: none;
}

.questions-section-container .questions-info-container .questions-list .open {
  height: auto;
}

.questions-section-container .questions-info-container .questions-list .open .item-view-container {
  height: 40%;
}

.questions-section-container .questions-info-container .questions-list .open .hidden-question-answer {
  max-width: 580px;
  margin: 15px 0;
  display: block;
}

@media (max-width: 600px) {
  .questions-section-container .questions-info-container .questions-list .open .hidden-question-answer {
    padding: 5px;
    font-size: 16px;
  }
}

.main-buttons-container {
  text-align: center;
  justify-content: center;
  gap: 16px;
  margin: 0 auto;
  display: flex;
}

.main-buttons-container .start-button {
  text-align: center;
  color: #fff;
  background: #2f3546;
  border: none;
  border-radius: 2000px;
  padding: 10px 24px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .08), 0 2px 4px rgba(0, 0, 0, .12);
}

.main-buttons-container .question-button {
  box-shadow: 0 1px 2px rgba(0, 0, 0, .08), 0 2px 4px rgba(0, 0, 0, .12);
}

.main-buttons-container .watch-wideo-button {
  text-align: center;
  color: #2f3546;
  background: #fff;
  border: none;
  border-radius: 2000px;
  justify-content: center;
  padding: 10px 24px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  display: flex;
}

.main-buttons-container .watch-wideo-button .main-menu-icon {
  vertical-align: bottom;
}

.main-buttons-container .watch-wideo-button p {
  margin-left: 5px;
}

@media (max-width: 600px) {
  .question-buttons-container {
    flex-direction: column;
    align-items: center;
    gap: 16px;
    display: flex;
  }

  .question-buttons-container .start-button, .question-buttons-container .watch-wideo-button {
    width: 80%;
    margin-right: 0;
  }
}

.still-have-questions-container {
  background: #f9fafb;
  border-radius: 16px;
  justify-content: space-between;
  margin-bottom: 100px;
  padding: 32px;
  display: flex;
}

@media (max-width: 615px) {
  .still-have-questions-container {
    flex-direction: column;
    gap: 32px;
  }
}

.still-have-questions-container .still-have-questions-text-container {
  flex-direction: column;
  gap: 8px;
  display: flex;
}

.still-have-questions-container .still-have-questions-text-container .still-have-questions-title {
  color: #101828;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.still-have-questions-container .still-have-questions-text-container .still-have-questions-desc {
  color: #475467;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

@media (max-width: 820px) {
  .still-have-questions-container .still-have-questions-text-container .still-have-questions-desc {
    max-width: 400px;
  }
}

.still-have-questions-container .still-have-questions-link {
  color: #fff;
  height: 48px;
  text-align: center;
  background: #2f3546;
  border: none;
  border-radius: 100px;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, .05);
}

.free-period-section {
  height: 360px;
  background-color: #f8f9fc;
}

@media (max-width: 650px) {
  .free-period-section {
    height: auto;
  }
}

.free-period-section .main-free-period-container {
  height: 100%;
  background-image: url("question-bg.a4e62e06.png");
  background-repeat: no-repeat;
  background-size: 500px 550px;
  padding: 96px 0;
}

.free-period-section .main-free-period-container .free-period-title {
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  line-height: 42px;
}

.free-period-section .main-free-period-container .free-period-title span {
  color: #438df6;
}

.free-period-section .main-free-period-container .free-period-benefits {
  justify-content: center;
  gap: 50px;
  display: flex;
}

@media (max-width: 700px) {
  .free-period-section .main-free-period-container .free-period-benefits {
    align-items: center;
    gap: 10px;
  }
}

@media (max-width: 650px) {
  .free-period-section .main-free-period-container .free-period-benefits {
    flex-direction: column;
    margin-top: 34px;
    margin-bottom: 32px;
  }
}

.free-period-section .main-free-period-container .free-period-benefits .free-period-benefits-items {
  padding: 32px 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  display: flex;
}

@media (max-width: 650px) {
  .free-period-section .main-free-period-container .free-period-benefits .free-period-benefits-items {
    padding: 9px 0;
  }
}

.free-period-section .main-free-period-container .free-period-benefits .free-period-benefits-items .free-period-icon {
  vertical-align: middle;
  stroke: #2f3546;
  margin-right: 8px;
}

@media (max-width: 600px) {
  .free-buttons-container {
    flex-direction: column;
    align-items: center;
    gap: 16px;
    display: flex;
  }

  .free-buttons-container .start-button, .free-buttons-container .watch-wideo-button {
    width: 80%;
    justify-content: center;
    margin-right: 0;
  }
}

.seo-main-container {
  padding: 66px 10px;
}

.seo-main-container .show-container {
  height: 250px;
  transition: all 1s linear;
  position: relative;
  overflow: hidden;
}

.seo-main-container .gradient-bottom:before {
  content: "";
  width: 100%;
  height: 55px;
  background: linear-gradient(to top, #fff, rgba(0, 0, 0, 0));
  position: absolute;
  bottom: 0;
}

.seo-main-container .opening {
  height: auto;
}

.seo-main-container .show-more-button {
  color: #438df6;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  margin: 10px 0 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  display: block;
}

.seo-main-container .show-more-button:after {
  content: "➜";
  color: #438df6;
  vertical-align: -1px;
  opacity: 0;
  margin-left: -2px;
  font-size: 15px;
  font-weight: 600;
  line-height: 15px;
  transition: all .2s;
}

.seo-main-container .show-more-button:hover:after {
  opacity: 1;
  margin-left: 5px;
}

.seo-inform-container:not(:last-child) {
  margin-bottom: 66px;
}

.seo-inform-container .seo-inform-title {
  color: #2f3546;
  margin-bottom: 16px;
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
}

.seo-inform-container .seo-inform-text {
  color: #525c7a;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.seo-inform-container .seo-inform-text:not(:last-child) {
  margin-bottom: 16px;
}

.seo-inform-container .title-inform {
  font-weight: 600;
}

.seo-inform-container .seo-inform-list .seo-inform-items {
  color: #525c7a;
  padding-left: 15px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.seo-inform-container .seo-inform-list .seo-inform-items:not(:last-child) {
  margin-bottom: 4px;
}

.seo-inform-container .seo-inform-list .seo-inform-items:before {
  content: ".";
  position: absolute;
  bottom: 5px;
  left: 5px;
}

.footer-container {
  border-bottom: 1px solid #ced2de;
  padding-top: 48px;
  padding-bottom: 48px;
  display: flex;
}

@media (max-width: 1050px) {
  .footer-container {
    flex-direction: column;
    gap: 24px;
  }
}

@media (max-width: 500px) {
  .footer-container {
    border-bottom: none;
    padding-left: 15px;
  }
}

.left-footer-container {
  width: 45%;
  margin-right: 5px;
}

@media (max-width: 650px) {
  .left-footer-container {
    width: 100%;
  }
}

.footer-text {
  max-width: 370px;
  color: #525c7a;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.right-footer-container {
  gap: 36px;
  display: flex;
}

@media (max-width: 1050px) {
  .right-footer-container {
    justify-content: space-between;
  }
}

@media (max-width: 830px) {
  .right-footer-container {
    flex-wrap: wrap;
  }
}

@media (max-width: 510px) {
  .right-footer-container {
    flex-direction: column;
  }
}

.pages {
  width: 135px;
}

.additional-pages {
  width: 230px;
}

.contacts {
  width: 210px;
}

.navigation-elements-title {
  color: #2f3546;
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.navigation-elements-items {
  color: #525c7a;
  cursor: pointer;
  padding: 4px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.footer-contacts-icon {
  vertical-align: middle;
  margin-right: 9px;
}

.contacts-email {
  color: #438df6;
}

.social-links {
  gap: 24px;
  margin-top: 24px;
  display: flex;
}

.social-links .footer-social-links {
  fill: #525c7a;
  transition: all .25s;
}

.social-links .footer-social-links:hover {
  fill: #438df6;
}

.mobile-links {
  flex-direction: column;
  gap: 8px;
  display: flex;
}

.mobile-links .download-mobile-footer-links {
  width: 160px;
  text-align: center;
  color: #fff;
  background: #2f3546;
  border-radius: 2000px;
  padding: 10px 24px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .12), 0 0 2px rgba(0, 0, 0, .12);
}

.mobile-links .download-mobile-footer-links .icon-footer-mobile-download-links {
  vertical-align: -6px;
  margin-right: 8px;
}

.footer-logo-link {
  margin-bottom: 20px;
}

.footer-main-logo {
  text-align: center;
  padding: 20px 0 48px;
}

@media (max-width: 500px) {
  .footer-main-logo {
    text-align: left;
    padding: 20px 0 48px 15px;
  }
}

.footer-link {
  color: #525c7a;
  transition: all .2s;
}

.footer-link:hover {
  color: #438df6;
}

.tariff-main-title-container {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.tariff-main-title-container .name-page {
  text-align: center;
  color: #525c7a;
  margin-bottom: 16px;
  padding-top: 65px;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
}

.tariff-main-title-container .tariff-main-title {
  text-align: center;
  max-width: 680px;
  margin-bottom: 32px;
  font-size: 60px;
  font-weight: 600;
  line-height: 64px;
}

@media (max-width: 500px) {
  .tariff-main-title-container .tariff-main-title {
    font-size: 36px;
    line-height: 42px;
  }
}

.tariff-main-title-container .safe-proposition {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background: rgba(255, 255, 255, .7);
  border-radius: 100px;
  margin-bottom: 16px;
  padding: 10px 22px;
  display: flex;
}

.tariff-main-title-container .safe-proposition p {
  color: #438df6;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}

.switch {
  width: 48px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.switch input {
  display: none;
}

.slider {
  cursor: pointer;
  background: rgba(158, 195, 247, .5);
  transition: all .5s;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: -3px;
}

.slider:hover {
  box-shadow: 0 0 0 4px rgba(67, 141, 246, .5);
}

.slider:before {
  content: "";
  height: 18px;
  width: 18px;
  background: #fff;
  border-radius: 10px;
  transition: all .4s;
  position: absolute;
  bottom: 3px;
  left: 3px;
  box-shadow: 0 1px 3px rgba(16, 24, 40, .1), 0 1px 2px rgba(16, 24, 40, .06);
}

input:checked + .slider {
  background-color: #525c7a;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ccc;
}

input:checked + .slider:before {
  transform: translateX(27px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.checkbox-container {
  gap: 20px;
  display: flex;
}

@media (max-width: 400px) {
  .checkbox-container {
    gap: 10px;
  }
}

.text-checkbox {
  color: #525c7a;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.text-checkbox span {
  color: #000;
  font-weight: 600;
}

.tariff-choise-contsiner {
  justify-content: center;
  gap: 24px;
  padding-top: 110px;
  display: flex;
}

@media (max-width: 950px) {
  .tariff-choise-contsiner {
    flex-direction: column;
    align-items: center;
  }
}

.tariff-choise-contsiner .tariff-choise {
  width: 395px;
  background: #fff;
  border-radius: 32px;
  transition: all .5s;
  box-shadow: 0 0 8px rgba(0, 0, 0, .04), 0 2px 2px rgba(0, 0, 0, .08), 0 4px 8px rgba(0, 0, 0, .12);
}

.tariff-choise-contsiner .tariff-choise:hover {
  box-shadow: 0 0 1px 3px rgba(67, 141, 246, .6), 0 0 8px rgba(0, 0, 0, .04), 0 2px 2px rgba(0, 0, 0, .08), 0 4px 8px rgba(0, 0, 0, .12);
}

.tariff-choise-contsiner .tariff-choise .main-tariff-info {
  border-bottom: 1px solid #ced2de;
  padding: 32px;
}

@media (max-width: 400px) {
  .tariff-choise-contsiner .tariff-choise {
    width: 100%;
  }
}

.favorite-tariff-container {
  justify-content: space-between;
  display: flex;
}

.favorite-tariff-container .favorite-tariff {
  width: 76px;
  height: 32px;
  text-align: center;
  color: #2f3546;
  background: #ced2de;
  border-radius: 2000px;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
}

.tariff-name {
  color: #2f3546;
  margin-bottom: 32px;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  transition: all .5s;
}

.time-tariff {
  color: #525c7a;
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  transition: all .5s;
}

.price-tariff {
  color: #2f3546;
  margin-bottom: 32px;
  font-size: 60px;
  font-weight: 500;
  line-height: 64px;
  transition: all .5s;
}

.buy-tariff-button {
  width: 100%;
  height: 40px;
  text-align: center;
  color: #fff;
  background: #2f3546;
  border: none;
  border-radius: 2000px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  transition: all .5s;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .08), 0 2px 4px rgba(0, 0, 0, .12);
}

.benefits-tariff-list {
  padding: 32px;
}

.benefits-tariff-list .benefits-tariff-item {
  color: #2f3546;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}

.benefits-tariff-list .benefits-tariff-item:not(:last-child) {
  margin-bottom: 8px;
}

.benefits-tariff-list .icon-benefits-tariff {
  vertical-align: middle;
  stroke: #525c7a;
  margin-right: 8px;
}

.active-tariff {
  background: #2f3546 !important;
  box-shadow: 0 0 8px rgba(0, 0, 0, .04), 0 2px 2px rgba(0, 0, 0, .08), 0 4px 8px rgba(0, 0, 0, .12) !important;
}

.active-tariff .main-tariff-info .tariff-name, .active-tariff .main-tariff-info .price-tariff {
  color: #fff;
}

.active-tariff .main-tariff-info .time-tariff {
  color: #989eae;
}

.active-tariff .main-tariff-info .buy-tariff-button {
  color: #2f3546;
  background: #fff;
}

.active-tariff .benefits-tariff-item {
  color: #fff;
}

.active-tariff .icon-benefits-tariff {
  stroke: #989eae;
}

.help-customers-container {
  padding-bottom: 80px;
}

.commerce-small-container {
  height: 500px;
  width: 100%;
  display: flex;
}

@media (max-width: 1100px) {
  .commerce-small-container {
    height: auto;
  }
}

@media (max-width: 850px) {
  .commerce-small-container {
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 851px) {
  .commerce-small-container .mobile-container {
    display: none;
  }
}

.commerce-small-container .text-container {
  width: 50%;
  background-size: 100%;
}

@media (max-width: 850px) {
  .commerce-small-container .text-container {
    width: 100%;
    flex-direction: column;
    margin-bottom: 32px;
    display: flex;
  }
}

.commerce-small-container .text-container .commerce-section-title {
  max-width: 255px;
  margin-bottom: 16px;
  font-size: 36px;
  font-weight: 600;
  line-height: 42px;
}

@media (max-width: 1400px) {
  .commerce-small-container .text-container .commerce-section-title {
    font-size: 30px;
  }
}

@media (max-width: 850px) {
  .commerce-small-container .text-container .commerce-section-title {
    text-align: center;
    margin: 0 auto;
  }
}

.commerce-small-container .text-container .commerce-section-text {
  max-width: 400px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

@media (max-width: 1400px) {
  .commerce-small-container .text-container .commerce-section-text {
    font-size: 16px;
  }
}

@media (max-width: 850px) {
  .commerce-small-container .text-container .commerce-section-text {
    text-align: center;
    margin: 0 auto;
  }
}

.commerce-small-container .text-container .icon-commerce {
  margin: 0 auto 15px;
}

@media (max-width: 1100px) {
  .commerce-small-container .text-container .icon-commerce {
    margin-bottom: 0;
  }
}

@media (max-width: 850px) {
  .commerce-small-container .text-container .icon-commerce {
    margin-bottom: 15px;
  }
}

.commerce-small-container .text-commerce-container {
  background-image: url("e-commerce-bg.6f3e935a.png");
  background-repeat: no-repeat;
  padding: 37px 99px 99px 114px;
}

@media (max-width: 1100px) {
  .commerce-small-container .text-commerce-container {
    padding: 10px;
  }
}

@media (max-width: 850px) {
  .commerce-small-container .text-commerce-container {
    background-position: center;
    background-size: 600px 400px;
  }
}

.commerce-small-container .text-api-container {
  background-image: url("api-bg.c8199dfd.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 650px 500px;
  padding: 69px 99px 110px 146px;
}

@media (max-width: 1100px) {
  .commerce-small-container .text-api-container {
    background-size: 550px 500px;
    padding: 10px;
  }
}

@media (max-width: 850px) {
  .commerce-small-container .text-api-container {
    background-size: 600px 400px;
  }
}

.commerce-small-container .commerce-image-container {
  width: 50%;
}

@media (max-width: 850px) {
  .commerce-small-container .commerce-image-container {
    width: 80%;
  }
}

@media (max-width: 500px) {
  .commerce-small-container .commerce-image-container {
    width: 100%;
  }
}

@media (max-width: 550px) {
  .commerce-small-container .commerce-image-container .commerce-image {
    display: none;
  }
}

@media (min-width: 551px) {
  .commerce-small-container .commerce-image-container .commerce-image-mobile {
    display: none;
  }
}

.commerce-info-containers .commerce-small-container .opport-list-container {
  padding: 10px 50px 99px;
}

.opportunities-main-container {
  flex-direction: column;
  align-items: center;
  padding: 65px 0 110px;
  display: flex;
}

.opportunities-main-container .opportunities-text {
  text-align: center;
  color: #525c7a;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
}

.opportunities-main-container .opportunities-title {
  text-align: center;
  color: #2f3546;
  max-width: 680px;
  font-size: 60px;
  font-weight: 600;
  line-height: 64px;
}

.commerce-small-container:not(:last-child) {
  margin-bottom: 80px;
}

.second {
  margin-top: 16px !important;
}

.commerce-section-title-opp {
  max-width: 420px !important;
}

.icon-opp {
  stroke: #41416e;
  vertical-align: bottom;
  margin: 0;
}

@media (max-width: 850px) {
  .opp-list {
    margin: 16px auto 0;
  }
}

.opp-list li {
  margin-bottom: 5px;
}

@media (max-width: 550px) {
  .commerce-image-container .commerce-image {
    display: none;
  }
}

@media (min-width: 551px) {
  .commerce-image-container .commerce-image-mobile {
    display: none;
  }
}

@media (max-width: 500px) {
  .about-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.about-container .about-container-team-work-first {
  justify-content: space-between;
  display: flex;
}

@media (max-width: 1050px) {
  .about-container .about-container-team-work-first {
    flex-direction: column;
  }
}

.about-container .about-container-team-work-first .text-first-team-conttainer {
  width: 50%;
}

@media (max-width: 1050px) {
  .about-container .about-container-team-work-first .text-first-team-conttainer {
    width: 100%;
    margin-bottom: 40px;
  }
}

.about-container .about-container-team-work-first .text-first-team-conttainer .who-we-are {
  color: #525c7a;
  margin-top: 40px;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
}

@media (min-width: 800px) and (max-width: 1050px) {
  .about-container .about-container-team-work-first .text-first-team-conttainer .who-we-are {
    text-align: center;
  }
}

@media (max-width: 500px) {
  .about-container .about-container-team-work-first .text-first-team-conttainer .who-we-are {
    font-size: 16px;
  }
}

.about-container .about-container-team-work-first .text-first-team-conttainer .about-us-title {
  color: #2f3546;
  margin-top: 20px;
  font-size: 60px;
  font-weight: 600;
  line-height: 64px;
}

@media (min-width: 800px) and (max-width: 1050px) {
  .about-container .about-container-team-work-first .text-first-team-conttainer .about-us-title {
    text-align: center;
  }
}

@media (max-width: 500px) {
  .about-container .about-container-team-work-first .text-first-team-conttainer .about-us-title {
    font-size: 36px;
  }
}

.about-container .about-container-team-work-first .text-first-team-conttainer .about-us-text {
  color: #525c7a;
  max-width: 590px;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

@media (min-width: 800px) and (max-width: 1050px) {
  .about-container .about-container-team-work-first .text-first-team-conttainer .about-us-text {
    text-align: center;
    max-width: 70%;
    margin: 20px auto 0;
  }
}

.about-container .about-container-team-work-first .first-team-image {
  width: 50%;
}

@media (max-width: 800px) {
  .about-container .about-container-team-work-first .first-team-image {
    width: 90%;
  }
}

.about-container .about-container-team-work-second {
  justify-content: space-between;
  margin-top: -160px;
  display: flex;
}

@media (max-width: 1250px) {
  .about-container .about-container-team-work-second {
    margin-top: -100px;
  }
}

@media (max-width: 1150px) {
  .about-container .about-container-team-work-second {
    margin-top: -70px;
  }
}

@media (max-width: 1050px) {
  .about-container .about-container-team-work-second {
    flex-direction: column;
    margin-top: 0;
  }
}

.about-container .about-container-team-work-second .second-team-image {
  width: 50%;
}

@media (min-width: 1051px) {
  .about-container .about-container-team-work-second .second-team-image {
    margin-left: -30px;
  }
}

@media (max-width: 1050px) {
  .about-container .about-container-team-work-second .second-team-image {
    order: 2;
  }
}

@media (max-width: 800px) {
  .about-container .about-container-team-work-second .second-team-image {
    width: 90%;
  }
}

.about-container .about-container-team-work-second .text-second-team-comtainer {
  width: 50%;
  background-image: url("e-commerce-bg.6f3e935a.png");
  background-repeat: no-repeat;
  background-size: 600px 800px;
  padding: 260px 0 0 120px;
}

@media (max-width: 1050px) {
  .about-container .about-container-team-work-second .text-second-team-comtainer {
    width: 100%;
    background-size: 600px 400px;
    margin: 0 auto;
    padding: 70px 0 10px;
  }
}

@media (max-width: 600px) {
  .about-container .about-container-team-work-second .text-second-team-comtainer {
    background-size: 400px 400px;
  }
}

@media (max-width: 400px) {
  .about-container .about-container-team-work-second .text-second-team-comtainer {
    background-size: 300px 400px;
  }
}

.about-container .about-container-team-work-second .text-second-team-comtainer .about-us-title {
  color: #2f3546;
  margin-top: 20px;
  font-size: 60px;
  font-weight: 600;
  line-height: 64px;
}

@media (min-width: 800px) and (max-width: 1050px) {
  .about-container .about-container-team-work-second .text-second-team-comtainer .about-us-title {
    text-align: center;
  }
}

@media (max-width: 500px) {
  .about-container .about-container-team-work-second .text-second-team-comtainer .about-us-title {
    font-size: 36px;
  }
}

.about-container .about-container-team-work-second .text-second-team-comtainer .about-us-text {
  color: #525c7a;
  max-width: 590px;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

@media (min-width: 800px) and (max-width: 1050px) {
  .about-container .about-container-team-work-second .text-second-team-comtainer .about-us-text {
    text-align: center;
    margin: 20px auto 0;
  }
}

.about-container .about-container-team-work-second .text-second-team-comtainer .our-goals-list {
  margin-top: 36px;
  list-style: none;
}

.about-container .about-container-team-work-second .text-second-team-comtainer .our-goals-list .our-goals-item {
  max-width: 392px;
  color: #525c7a;
  margin-top: 20px;
  padding-left: 35px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.about-container .about-container-team-work-second .text-second-team-comtainer .our-goals-list .our-goals-item:before {
  content: url("checkcircle.8c185dc7.svg");
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 800px) and (max-width: 1050px) {
  .about-container .about-container-team-work-second .text-second-team-comtainer .our-goals-list .our-goals-item {
    max-width: 60%;
    margin: 20px auto 0;
  }
}

@media (max-width: 1050px) {
  .about-container .about-container-team-work-second .text-second-team-comtainer {
    order: 1;
  }
}

.our-team-container {
  padding-bottom: 110px;
}

.our-team-container .backdrop-title {
  background-image: url("question-bg.a4e62e06.png");
  background-position: -20px 0;
  background-repeat: no-repeat;
  background-size: 800px 300px;
  padding: 100px 0 50px;
}

.our-team-container .backdrop-title .our-team-title {
  color: #2f3546;
  font-size: 60px;
  font-weight: 600;
  line-height: 64px;
}

@media (min-width: 800px) and (max-width: 1050px) {
  .our-team-container .backdrop-title .our-team-title {
    text-align: center;
  }
}

@media (max-width: 500px) {
  .our-team-container .backdrop-title .our-team-title {
    font-size: 36px;
  }
}

.our-team-container .backdrop-title .our-team-description {
  color: #525c7a;
  max-width: 520px;
  margin-top: 16px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

@media (min-width: 800px) and (max-width: 1050px) {
  .our-team-container .backdrop-title .our-team-description {
    text-align: center;
    margin: 16px auto 0;
  }
}

.our-team-container .all-team-container {
  flex-wrap: wrap;
  gap: 30px;
  display: flex;
}

@media (max-width: 1280px) {
  .our-team-container .all-team-container {
    justify-content: center;
  }
}

.our-team-container .all-team-container .developer-container {
  width: 290px;
  background: #f8f9fc;
  border-radius: 40px;
  margin: 24px 0;
  padding: 32px;
  box-shadow: 0 0 8px rgba(0, 0, 0, .04), 0 2px 2px rgba(0, 0, 0, .08), 0 4px 8px rgba(0, 0, 0, .12);
}

.our-team-container .all-team-container .developer-container .developer-image {
  width: 112px;
  margin-bottom: 50px;
}

.our-team-container .all-team-container .developer-container .developer-name {
  text-align: center;
  color: #000;
  font-size: 24px;
  font-weight: 500;
  line-height: 26px;
}

.our-team-container .all-team-container .developer-container .developer-position {
  text-align: center;
  color: #525c7a;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.ai-section-title {
  text-align: center;
  text-shadow: 0 4px 4px rgba(0, 0, 0, .25);
  max-width: 1044px;
  margin: 0 auto;
  font-size: 60px;
  font-weight: 600;
  line-height: 64px;
}

@media (max-width: 800px) {
  .ai-section-title {
    text-shadow: none;
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
  }
}

@media (max-width: 500px) {
  .ai-section-title {
    max-width: 340px;
    font-size: 32px;
    line-height: 42px;
  }
}

.ai-section-text {
  text-align: center;
  max-width: 707px;
  margin: 24px auto 32px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

@media (max-width: 800px) {
  .ai-section-text {
    font-size: 16px;
  }
}

.eng-ai-section-image {
  margin-top: 60px !important;
}

.ai-mobile-main-section-image {
  margin-top: 11px;
}

@media (min-width: 501px) {
  .ai-mobile-main-section-image {
    display: none;
  }
}

.third-ai-title {
  text-align: center;
  text-shadow: 0 4px 4px rgba(0, 0, 0, .25);
  height: 64px;
  margin: 0 auto;
  font-size: 60px;
  font-weight: 600;
  line-height: 64px;
  display: block;
}

.third-ai-title:after {
  content: "";
  border-right: 3px solid #2f3546;
  animation: cursor .75s step-end infinite;
}

@media (max-width: 800px) {
  .third-ai-title {
    text-shadow: none;
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
  }
}

@media (max-width: 500px) {
  .third-ai-title {
    height: 32px;
    font-size: 32px;
    line-height: 42px;
  }
}

@media (max-width: 380px) {
  .third-ai-title {
    height: 50px;
  }
}

@keyframes cursor {
  0% {
    border-color: #2f3546;
  }

  50% {
    border-color: rgba(0, 0, 0, 0);
  }

  100% {
    border-color: #2f3546;
  }
}

@keyframes typing {
  from {
    width: 0;
  }
}

.examples-container {
  justify-content: center;
  gap: 24px;
  display: flex;
}

@media (max-width: 1260px) {
  .examples-container {
    gap: 28px;
    margin-top: 28px;
  }
}

@media (max-width: 768px) {
  .examples-container {
    flex-direction: column;
    align-items: center;
  }
}

.examples-container .example-card {
  max-width: 600px;
  height: 625px;
  background-color: #f8f9fc;
  border-radius: 40px;
}

@media (max-width: 768px) {
  .examples-container .example-card {
    height: 430px;
  }
}

.examples-container .example-card .example-card-images {
  border-radius: 40px 40px 0 0;
}

@media (max-width: 768px) {
  .examples-container .example-card .example-card-images {
    width: 340px;
    height: auto;
  }
}

.examples-container .example-card .example-card-info {
  flex-direction: column;
  align-items: center;
  padding: 30px 32px;
  display: flex;
}

.examples-container .example-card .example-card-info .text-example {
  text-align: center;
  color: #2f3546;
  margin-top: 16px;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
}

@media (max-width: 768px) {
  .examples-container .example-card .example-card-info .text-example {
    font-size: 20px;
  }
}

.ai-title {
  text-align: center;
  max-width: 875px;
  margin: auto auto 70px;
  font-size: 60px;
  font-weight: 600;
  line-height: 64px;
}

@media (max-width: 1000px) {
  .ai-title {
    width: 80%;
    font-size: 40px;
  }
}

@media (max-width: 400px) {
  .ai-title {
    width: 100%;
  }
}

.ai-schema-container {
  padding-top: 80px;
  padding-bottom: 150px;
}

@media (max-width: 700px) {
  .ai-schema-container {
    padding-bottom: 0;
  }
}

.ai-schema-title {
  text-align: center;
  margin: auto auto 13px;
  font-size: 60px;
  font-weight: 600;
  line-height: 64px;
}

@media (max-width: 400px) {
  .ai-schema-title {
    max-width: 250px;
  }

  .ai-schema-title:after {
    content: url("mob-arm.3d5bd51e.png");
    vertical-align: sub;
    margin-left: 15px;
  }
}

@media (min-width: 401px) {
  .ai-schema-title:after {
    content: url("benefit-title.a623f40c.png");
    vertical-align: sub;
    margin-left: 15px;
  }
}

@media (max-width: 1000px) {
  .ai-schema-title {
    width: 95%;
    font-size: 40px;
  }
}

@media (max-width: 400px) {
  .ai-schema-title {
    width: 90%;
    font-size: 30px;
    line-height: 38px;
  }
}

@media (max-width: 400px) {
  .ua-schema-title {
    max-width: 320px;
  }
}

.ai-schema-description {
  text-align: center;
  color: #525c7a;
  margin-bottom: 70px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

@media (max-width: 400px) {
  .ai-schema-description {
    width: 90%;
    margin: 0 auto;
    font-size: 16px;
  }
}

@media (max-width: 700px) {
  .ai-schema-image {
    display: none;
  }
}

.mobile-ai-schema-image {
  margin: 0 auto;
}

@media (min-width: 701px) {
  .mobile-ai-schema-image {
    display: none;
  }
}

.ai-watch-wideo-button {
  text-align: center;
  color: #fff;
  max-width: 400px;
  background: #2f3546;
  border: none;
  border-radius: 2000px;
  justify-content: center;
  margin: 32px auto 0;
  padding: 10px 24px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  display: flex;
}

@media (max-width: 701px) {
  .ai-watch-wideo-button {
    margin: 0 auto;
  }
}

.ai-watch-wideo-button .main-menu-icon {
  vertical-align: bottom;
  fill: #fff;
}

.ai-watch-wideo-button p {
  margin-left: 5px;
}

.ai-benefits-container {
  background-image: url("benefit-big-bg.5a01c22c.png");
  padding-top: 100px;
  padding-bottom: 95px;
}

@media (max-width: 700px) {
  .ai-benefits-container {
    background-position: 0 18%;
    background-repeat: no-repeat;
    padding-top: 50px;
  }
}

.ai-benefits-title {
  text-align: center;
  margin: auto auto 70px;
  font-size: 60px;
  font-weight: 600;
  line-height: 64px;
}

@media (max-width: 1000px) {
  .ai-benefits-title {
    width: 80%;
    font-size: 40px;
  }
}

@media (max-width: 950px) {
  .ai-benefits-title {
    margin-bottom: 20px;
  }
}

@media (max-width: 400px) {
  .ai-benefits-title {
    width: 100%;
    margin-bottom: 20px;
    font-size: 30px;
    line-height: 38px;
  }
}

.ai-choise-container {
  justify-content: center;
  gap: 24px;
  display: flex;
}

@media (max-width: 950px) {
  .ai-choise-container {
    flex-direction: column;
    align-items: center;
  }
}

.ai-choise-container .ai-choise {
  width: 444px;
  background: #fff;
  border-radius: 32px;
  transition: all .5s;
  box-shadow: 0 0 8px rgba(0, 0, 0, .04), 0 2px 2px rgba(0, 0, 0, .08), 0 4px 8px rgba(0, 0, 0, .12);
}

@media (max-width: 500px) {
  .ai-choise-container .ai-choise {
    width: 90%;
  }
}

.ai-choise-container .ai-choise:hover {
  box-shadow: 0 0 1px 3px rgba(67, 141, 246, .6), 0 0 8px rgba(0, 0, 0, .04), 0 2px 2px rgba(0, 0, 0, .08), 0 4px 8px rgba(0, 0, 0, .12);
}

.ai-choise-container .ai-choise .main-ai-info {
  border-bottom: 1px solid #ced2de;
  flex-direction: column;
  padding: 32px;
  display: flex;
}

.ai-choise-container .ai-choise .main-ai-info .icon-benefits-ai {
  margin: 0 auto 10px;
}

.ai-choise-container .ai-choise .main-ai-info .ai-benefits-text {
  text-align: center;
  color: #2f3546;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
}

.ai-choise-container .ai-choise .main-ai-info .ai-benefits-text .ai-benefits-bold {
  color: #222630;
  font-size: 26px;
  font-weight: 900;
}

.ai-choise-container .ai-choise .positive {
  stroke: #28c76f;
}

.ai-choise-container .ai-choise .negative {
  stroke: #ea5455;
}

.ai-choise-container .ai-choise .benefits-tariff-item {
  display: flex;
}

.span-benefits {
  font-size: 15px;
  font-weight: 900;
}

@media (max-width: 400px) {
  .ua-benefits-title {
    max-width: 330px;
  }
}

.ai-benefits-buttons-container {
  width: 444px;
  height: 50px;
  background: #fff;
  border-radius: 16px;
  margin: 0 auto 8px;
  padding: 4px 10px;
  display: flex;
  box-shadow: 0 0 8px rgba(0, 0, 0, .04), 0 2px 2px rgba(0, 0, 0, .08), 0 4px 8px rgba(0, 0, 0, .12);
}

@media (min-width: 950px) {
  .ai-benefits-buttons-container {
    display: none;
  }
}

@media (max-width: 500px) {
  .ai-benefits-buttons-container {
    width: 90%;
  }
}

.ai-benefits-buttons-container .ai-benefits-buttons {
  width: 50%;
  color: #2f3546;
  background: none;
  border: none;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.ai-benefits-buttons-container .first {
  border-right: 1px solid #dae6fb;
}

.opacity-button {
  opacity: .5;
}

.opacity-container {
  display: block !important;
}

@media (max-width: 950px) {
  .opacity-container-show {
    display: none;
  }
}

.ai-start-steps-container {
  padding-top: 80px;
  padding-bottom: 150px;
}

@media (max-width: 700px) {
  .ai-start-steps-container {
    padding-bottom: 50px;
  }
}

.ai-start-steps-container .ai-start-steps-text {
  text-align: center;
  margin: auto auto 60px;
  font-size: 60px;
  font-weight: 600;
  line-height: 64px;
}

@media (max-width: 1000px) {
  .ai-start-steps-container .ai-start-steps-text {
    width: 80%;
    font-size: 40px;
  }
}

@media (max-width: 400px) {
  .ai-start-steps-container .ai-start-steps-text {
    max-width: 340px;
    font-size: 30px;
    line-height: 38px;
  }
}

.ai-mobile-schema {
  max-width: 300px;
  margin: 0 auto;
}

@media (max-width: 400px) {
  .ai-mobile-schema {
    padding-left: 20px;
  }
}

.ai-mobile-schema .step-block-first-step {
  margin-bottom: 15px;
  position: relative;
}

.ai-mobile-schema .step-block-first-step:before {
  content: url("ai-first-step.9569a37b.png");
  position: absolute;
  top: -10px;
  left: -30px;
}

.ai-mobile-schema .step-block-second-step {
  margin-bottom: 15px;
  position: relative;
}

.ai-mobile-schema .step-block-second-step:before {
  content: url("ai-second-step.191e7e8b.png");
  position: absolute;
  top: -10px;
  left: -30px;
}

.ai-mobile-schema .step-block-third-step {
  margin-bottom: 15px;
  position: relative;
}

.ai-mobile-schema .step-block-third-step:before {
  content: url("ai-third-step.68cf331e.png");
  position: absolute;
  top: -10px;
  left: -30px;
}

.ai-mobile-schema .step-block-four-step {
  margin-bottom: 15px;
  position: relative;
}

.ai-mobile-schema .step-block-four-step:before {
  content: url("ai-four-step.57a3aed8.png");
  position: absolute;
  top: -10px;
  left: -30px;
}

.ai-mobile-schema .step-block-fifth-step {
  width: 95%;
  margin-bottom: 20px;
  position: relative;
}

.ai-mobile-schema .step-block-fifth-step:before {
  content: url("ai-fifth-step.dcfdbf81.png");
  position: absolute;
  top: -10px;
  left: -30px;
}

.ai-mobile-schema .step-block-first-step-eng {
  margin-bottom: 40px;
  position: relative;
}

.ai-mobile-schema .step-block-first-step-eng:before {
  content: url("ai-first-step.9569a37b.png");
  position: absolute;
  top: -10px;
  left: -30px;
}

.ai-mobile-schema .step-block-second-step-eng {
  margin-bottom: 20px;
  position: relative;
}

.ai-mobile-schema .step-block-second-step-eng:before {
  content: url("ai-second-step.191e7e8b.png");
  position: absolute;
  top: -10px;
  left: -30px;
}

.ai-mobile-schema .step-block-third-step-eng {
  margin-bottom: 40px;
  position: relative;
}

.ai-mobile-schema .step-block-third-step-eng:before {
  content: url("ai-third-step.68cf331e.png");
  position: absolute;
  top: -10px;
  left: -30px;
}

.ai-mobile-schema .step-block-four-step-eng {
  margin-bottom: 45px;
  position: relative;
}

.ai-mobile-schema .step-block-four-step-eng:before {
  content: url("ai-four-step.57a3aed8.png");
  position: absolute;
  top: -10px;
  left: -30px;
}

.ai-mobile-schema .step-block-fifth-step-eng {
  width: 95%;
  margin-bottom: 20px;
  position: relative;
}

.ai-mobile-schema .step-block-fifth-step-eng:before {
  content: url("ai-fifth-step.dcfdbf81.png");
  position: absolute;
  top: -10px;
  left: -30px;
}

.ai-mobile-schema .ai-mobile-schema-title {
  color: #2f3546;
  margin-bottom: 4px;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
}

.ai-mobile-schema .ai-mobile-schema-text {
  color: #2f3546;
  opacity: .7;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

@media (min-width: 701px) {
  .ai-mobile-schema {
    display: none;
  }
}

/*# sourceMappingURL=index.2600fecc.css.map */
