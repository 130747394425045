.ai-start-steps-container {
    padding-top: 80px;
    padding-bottom: 150px;

    @media (max-width: 700px) {
        padding-bottom: 50px;
    }

            .ai-start-steps-text {
                    font-weight: 600;
                    font-size: 60px;
                    line-height: 64px;
                    text-align: center;
                    margin: auto;
                    margin-bottom: 60px;
            
                    @media (max-width: 1000px) {
                        font-size: 40px;
                        width: 80%;
                    }
            
                    @media (max-width: 400px) {
                        font-size: 30px;
                        line-height: 38px;
                        max-width: 340px;
                    }
                }
}
              
      
.ai-mobile-schema {
    max-width: 300px;
    margin: 0 auto;

    @media (max-width: 400px) {
        padding-left: 20px;
    }


    .step-block-first-step {
        margin-bottom: 15px;
        position: relative;

        &::before {
        content: url('../images/styles/ai-first-step.png');
        position: absolute;
        left: -30px;
        top: -10px;
        }

    }

        .step-block-second-step {
        margin-bottom: 15px;
        position: relative;

        &::before {
        content: url('../images/styles/ai-second-step.png');
        position: absolute;
        left: -30px;
        top: -10px;
        }

    }
        .step-block-third-step {
            margin-bottom: 15px;
            position: relative;
    
            &::before {
                content: url('../images/styles/ai-third-step.png');
                position: absolute;
                left: -30px;
                top: -10px;
            }
    
        }
        .step-block-four-step {
            margin-bottom: 15px;
            position: relative;
    
            &::before {
                content: url('../images/styles/ai-four-step.png');
                position: absolute;
                left: -30px;
                top: -10px;
            }
    
        }

        .step-block-fifth-step {
            width: 95%;
            margin-bottom: 20px;
            position: relative;
            
            &::before {
              content: url('../images/styles/ai-fifth-step.png');
               position: absolute;
               left: -30px;
               top: -10px;
              }
            
        }


                 .step-block-first-step-eng {
                     margin-bottom: 40px;
                     position: relative;
        
                     &::before {
                         content: url('../images/styles/ai-first-step.png');
                         position: absolute;
                         left: -30px;
                         top: -10px;
                     }
        
                 }
        
                 .step-block-second-step-eng {
                     margin-bottom: 20px;
                     position: relative;
        
                     &::before {
                         content: url('../images/styles/ai-second-step.png');
                         position: absolute;
                         left: -30px;
                         top: -10px;
                     }
        
                 }
        
                 .step-block-third-step-eng {
                     margin-bottom: 40px;
                     position: relative;
        
                     &::before {
                         content: url('../images/styles/ai-third-step.png');
                         position: absolute;
                         left: -30px;
                         top: -10px;
                     }
        
                 }
        
                 .step-block-four-step-eng {
                     margin-bottom: 45px;
                     position: relative;
        
                     &::before {
                         content: url('../images/styles/ai-four-step.png');
                         position: absolute;
                         left: -30px;
                         top: -10px;
                     }
        
                 }
        
                 .step-block-fifth-step-eng {
                     width: 95%;
                     margin-bottom: 20px;
                     position: relative;
        
                     &::before {
                         content: url('../images/styles/ai-fifth-step.png');
                         position: absolute;
                         left: -30px;
                         top: -10px;
                     }
        
                 }


         .ai-mobile-schema-title {
             font-weight: 500;
             font-size: 20px;
             line-height: 20px;
             color: #2F3546;
             margin-bottom: 4px;
         }
    
         .ai-mobile-schema-text {
             font-weight: 400;
             font-size: 18px;
             line-height: 24px;
             color: #2F3546;
             opacity: 0.7;
         }

    @media (min-width: 701px) {
       display: none; 
    }
}