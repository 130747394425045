@import './base/common';
@import './base/container';
@import './layout/header';
@import './layout/main';
@import './layout/creatibes';
@import './layout/communication';
@import './layout/benefit';
@import './layout/e-commerce';
@import './layout/mobile-app';
@import './layout/questions';
@import './layout/still-have-questions';
@import './layout/free-preiod';
@import './layout/seo-container';
@import './layout/footer';
@import './layout/tarif-main';
@import './layout/help-customers';
@import './layout/opportunities-main';
@import './layout/about-main';
@import './layout/about-our-team';
@import './layout/blog-main';
@import './layout/blog-one-page-instruction';
@import './layout/congratulations-blog';
@import './layout/contact-main';
@import './layout/ai-main';
@import './layout/ai-work-system';
@import './layout/ai-schema';
@import './layout/ai-benefits';
@import './layout/ai-start-steps';
@import './layout/modal';



