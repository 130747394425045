.commerce-info-containers {
    padding: 64px 104px;

    @media (max-width: 1400px) {
        padding: 64px 0;
    }

    .automatisation-commerce-small-container {
        @media (max-width: 850px) {
           .commerce-image-container {
            order: 1;
           }
           .text-automatisation-container {
            order: 0;
           }
        }
    }

        .commerce-small-container {
            display: flex;
            height: auto;
            width: 100%;

            &:not(:last-child) {
                margin-bottom: 80px;  
             }

            @media (max-width: 1100px) {
                height: auto;
             }

             @media (max-width: 850px) {
                flex-direction: column;
                align-items: center;
             }


            .mobile-container {
                @media (min-width: 851px) {
                    display: none;
                 }  
            }

           

            .text-container {
                width: 50%;
                background-size: 100%;

                @media (max-width: 850px) {
                    display: flex;
                     flex-direction: column;
                    margin-bottom: 32px;
                    width: 100%;
                 }

                .commerce-section-title {
                    font-weight: 600;
                    font-size: 36px;
                    line-height: 42px;
                    max-width: 100%;
                    margin-bottom: 16px;

                    @media (max-width: 1400px) {
                        font-size: 30px; 
                        // max-width: 205px;
                    }

                    @media (max-width: 850px) {
                       text-align: center;
                       margin: 0 auto;
                     }
                }
                .commerce-section-text {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 24px;
                    max-width: 400px;
                    @media (max-width: 1400px) {
                        font-size: 16px; 
                    }
                    @media (max-width: 850px) {
                        text-align: center;
                        margin: 0 auto;
                      }
                }

                .icon-commerce {
                    margin-bottom: 32px;
                    margin: 0 auto;

                    @media (max-width: 1100px) {
                        margin-bottom: 0;
                     }

                     @media (max-width: 850px) {
                        margin-bottom: 15px;
                      }
                }
            }

            .desctop-container {
                @media (max-width: 850px) {
                    display: none;
                 }
            }

            .text-commerce-container {
                background-image: url('../images/background/e-commerce-bg.png');
                padding: 37px 80px 99px 50px;
                background-repeat: no-repeat;

                @media (max-width: 1100px) {
                   padding: 10px;
                }

                @media (max-width: 850px) {
                    background-position: center;
                    background-size: 600px 400px;
                }
            }
            .text-automatisation-container {
                background-image: url('../images/background/api-bg.png');
                    padding: 15px 40px 0 100px;
                    background-size: 650px 500px;
                    background-repeat: no-repeat;
                    background-position: center;

                    .commerce-section-title {
                        max-width: 315px;
                    }

                    .commerce-section-text {
                        max-width: 350px;
                    }
                
                    @media (max-width: 1100px) {
                        padding: 10px;
                        background-size: 550px 500px;
                    }
                
                    @media (max-width: 850px) {
                        background-size: 600px 400px;
                    }

                                          .show-more-button {
                                              display: block;
                                              background-color: transparent;
                                              border: none;
                                              color: #438DF6;
                                              font-weight: 600;
                                              font-size: 14px;
                                              line-height: 24px;
                                              padding: 0;
                    
                                              &::after {
                                                  content: '➜';
                                                  color: #438df6;
                                                  font-size: 15px;
                                                  font-weight: 600;
                                                  line-height: 15px;
                                                  margin-left: -2px;
                                                  vertical-align: -1px;
                                                  opacity: 0;
                                                  transition: all .2s ease;
                                              }
                    
                                              &:hover::after {
                                                  margin-left: 5px;
                                                  opacity: 1;
                                              }
                                          }

                                          .automatisation-title {
                                            max-width: 415px
                                          }

                                          @media (max-width: 1100px) {
                                                .automatisation-title {
                                                        max-width: 515px;
                                                    }
                                                
                                                    .automatisation-text {
                                                        max-width: 515px;
                                                    }
                                          }
            }
            .text-api-container {
                background-image: url('../images/background/api-bg.png');
                padding: 69px 99px 110px 146px;
                background-size: 650px 500px;
                background-repeat: no-repeat;
                background-position: center;

                @media (max-width: 1100px) {
                    padding: 10px;
                    background-size: 550px 500px;
                 }
                 @media (max-width: 850px) {
                    background-size: 600px 400px;
                }
            }
            .commerce-image-container {
                width: 50%;

                @media (max-width: 850px) {
                    width: 80%;
                  }
                  @media (max-width: 500px) {
                    width: 100%;
                  }

                  .automatisation-image {
                    @media (max-width: 550px) {
                        display: none;
                    }
                  }

                  .automatisation-image-mobile {
                    @media (min-width: 551px) {
                            display: none;
                        }
                  }
            }

       
        }
}
