.still-have-questions-container {
   margin-bottom: 100px; 
   padding: 32px;
   background: #F9FAFB;
   border-radius: 16px;    
   display: flex;
   justify-content: space-between;

   @media (max-width: 615px) {
    flex-direction: column;
    gap: 32px;
   }

   .still-have-questions-text-container {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .still-have-questions-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #101828;
    }
    .still-have-questions-desc {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: #475467;

        @media (max-width: 820px) {
            max-width: 400px;
        }
    }
   }

   .still-have-questions-link {
    background: #2F3546;
    border: 1px solid #2F3546;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 100px;
    padding: 12px 20px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    height: 48px;
    text-align: center;
    border: none;
   }
}

