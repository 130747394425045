.mobile-app-section-container {
    padding-bottom: 50px;
    
    .mobile-app-main-flex-container {
        display: flex;
        background: #F8F9FC;
        border-radius: 24px;

        @media (max-width: 1000px) {
            flex-direction: column;
            align-items: center;
            gap: 20px;
        }

        .mobile-app-text-container {
            width: 50%;
            padding: 100px 64px 0px 32px;

            @media (max-width: 1100px) {
                padding: 50px 64px 0 32px;
            }

            @media (max-width: 1000px) {
                 width: 100%;
             }

           @media (max-width: 500px) {
                padding: 0 20px;
            }

            .mobile-app-title {
                font-weight: 600;
                font-size: 48px;
                line-height: 60px;
                letter-spacing: -0.02em;
                color: #101828;
                margin-bottom: 8px;
            }

            .mobile-app-text {
                font-weight: 400;
                font-size: 20px;
                line-height: 30px;
                color: #475467;
                margin-bottom: 40px;
            }

            .able-soon-text {
                margin: 20px 0;
            }

                        .mobile-app__mobile-links {
                            display: flex;
                            gap: 12px;

                                @media (max-width: 500px) {
                                       justify-content: center;
                                    }
            
                            .download-mobile-footer-links {
                                width: 160px;
                                padding: 10px 24px;
                                background: #2F3546;
                                box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.12);
                                border-radius: 2000px;
                                font-family: 'Inter';
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 20px;
                                text-align: center;
                                color: #FFFFFF;

                                    @media (max-width: 500px) {
                                            width: 180px;
                                        }
            
                                .icon-footer-mobile-download-links {
                                    vertical-align: -6px;
                                    margin-right: 8px;
                                }
            
                            }
                        }
        }
        .mobile-app-image-container {
            width: 50%;

            @media (max-width: 1000px) {
                    width: 80%;
                }

            .mobile-app-image-desctop {
                    @media (max-width: 500px) {
                            display: none;
                    }
            }

            .mobile-app-image-mob {
          
                    @media (min-width: 500px) {
                            display: none;
                    }
            }
        }
    }
}
