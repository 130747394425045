
.footer-container {
    display: flex;
    padding-top: 48px;
    padding-bottom: 48px;
    border-bottom: 1px solid #CED2DE;

    @media (max-width: 1050px) {
        flex-direction: column;
        gap: 24px;
    }

    @media (max-width: 500px) {
        padding-left: 15px;
        border-bottom: none;
    } 
}

.left-footer-container {
    width: 45%;
    margin-right: 5px;

    @media (max-width: 650px) {
        width: 100%;
    }
}

.footer-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px; 
    max-width: 370px;
    color: #525C7A;
}

.right-footer-container {
    display: flex;
    gap: 36px;

    @media (max-width: 1050px) {
        justify-content: space-between;
    }

    @media (max-width: 830px) {
        flex-wrap: wrap;
        
    }
    @media (max-width: 510px) {
    flex-direction: column;
    }
}

.pages {
    width: 135px;
}

.additional-pages {
    width: 230px;
}

.contacts{
    width: 210px;
}

.navigation-elements-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px; 
    color: #2F3546;
    margin-bottom: 4px;
}

.navigation-elements-items {
    padding: 4px 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #525C7A;
    cursor: pointer;
   } 

   .footer-contacts-icon {
    vertical-align: middle;
    margin-right: 9px;
}

.contacts-email {
    color: #438DF6;
}

.social-links {
    margin-top: 24px;
    display: flex;
    gap: 24px;

    .footer-social-links {
            transition: all .25s ease;
            fill: #525C7A;
    }

    .footer-social-links:hover {
            fill: #438DF6;
    }
}

.mobile-links {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .download-mobile-footer-links {
        width: 160px;
        padding: 10px 24px;
        background: #2F3546;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.12);
        border-radius: 2000px;
        font-family: 'Inter';
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #FFFFFF;

        .icon-footer-mobile-download-links {
            vertical-align: -6px;
            margin-right: 8px;
        }
            
    }
}

  .footer-logo-link {
     margin-bottom: 20px; 
}

.footer-main-logo {
text-align: center;
padding: 20px 0 48px 0;

@media (max-width: 500px) {
    text-align: left;
    padding: 20px 0 48px 15px;
} 
}

.footer-link {
    color: #525C7A;
    transition: all .2s ease;

    &:hover {
        color: #438DF6;
    }
}