.free-period-section {
    background-color: #F8F9FC;
    height: 360px;

    @media (max-width: 650px) {
        height: auto;
    }

    .main-free-period-container {
        background-image: url('../images/background/question-bg.png');
        background-repeat: no-repeat;
        background-size: 500px 550px;
        height: 100%;
        padding: 96px 0;

            .free-period-title {
                font-weight: 600;
                font-size: 36px;
                line-height: 42px;  
                text-align: center;
    
                span {
                    color: #438DF6;
                }
            }
    
           
   

        .free-period-benefits {
            display: flex;
            justify-content: center;
            gap: 50px;

            @media (max-width:700px) {
                gap: 10px;
                align-items: center;
            }

            @media (max-width: 650px) {
                margin-top: 34px;
                margin-bottom: 32px;
                flex-direction: column;
            }

            .free-period-benefits-items {
                display: flex;
                padding: 32px 0;
                font-weight: 500;
                font-size: 16px;
                line-height: 25px;

                @media (max-width: 650px) {
                    padding: 9px 0;
                }

                .free-period-icon {
                    vertical-align: middle;
                    margin-right: 8px;
                    stroke: #2f3546;
                }
            }
        }
    }
}

.free-buttons-container {
    @media (max-width: 600px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;

        .start-button,
        .watch-wideo-button {
            justify-content: center;
            width: 80%;
            margin-right: 0;
        }
    }
}