.header-elements-container {
    display: flex;
    justify-content: space-between;
    height: 92px;
    // overflow: hidden;

    @media (max-width: 500px) {
    padding: 0 10px;
    }
}

.header {
    background-image: url('../images/background/main-bg.png');
    background-size: 100% 875px;
    background-repeat: no-repeat;
}

.tariff-header {
    background-image: url('../images/background/main-bg.png');
    background-size: 100% 875px;
    background-repeat: no-repeat;
}

.opportunities-header {
    background-image: url('../images/background/main-bg.png');
    background-size: 100% 705px;
    background-repeat: no-repeat; 
}

.blog-header {
    background-image: url('../images/background/main-bg.png');
    background-size: 100% 608px;
    background-repeat: no-repeat;   
}

.one-page-blog-header {
    background-image: url('../images/background/main-bg.png');
    background-size: 100% 455px;
    background-repeat: no-repeat; 
}

.contact-header {
    background-image: url('../images/background/main-bg.png');
    background-size: 100% 617px;
    background-repeat: no-repeat;  
}

.about-us-header {
    background-image: url('../images/background/main-bg.png');
    background-size: 100% 455px;
    background-repeat: no-repeat;  
}

.ai-header {
    background-image: url('../images/background/main-bg.png');
    background-size: 100% 875px;
    background-repeat: no-repeat;
}

.container {
    transition: all .5s ease;
}

.container.active {
    background-color: #FFFFFF;
}

.left-side-header,
.right-side-header {
    position: relative;
    display: flex;
    margin: auto 0;
    gap: 24px;
}

.header-navigation {
    display: flex;
    gap: 16px;

    .navigation-item {
        margin: auto 0;

        &:hover {
            .navigation-link {
                color: #2F3546;
                font-weight: 600;
            }
            }
       .navigation-link {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #525C7A;
        transition: all .5s ease;

       
       } 

       .active-page {
        font-weight: 600;
        color: #2F3546;
       }
    }

    @media (max-width: 1010px) {
        display: none;
    }
}

.language-menu {
    position: absolute;
    top: -10px;
    left: -95px;
display: flex;
gap: 3px;
font-weight: 600;
font-size: 16px;
line-height: 20px;
color: #516785;
background: rgba(255, 255, 255, 0.56);
border-radius: 12px;
padding: 10px 16px;
cursor: pointer;
height: 40px;
overflow: hidden;

#current-lang {
    transition: all .5s ease;
}

&:hover {
    #current-lang {
color: #438DF6;
    }
    .language-menu-icon {
        stroke: #438DF6;
    }
}

.other-lang {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}

@media (max-width: 1010px) {
    display: none;
}

.language-menu-icon {
    vertical-align: bottom;
    fill: transparent;
    stroke: #516785;
    transition: all .5s ease;
}
}

// .open-beautiful {
//     position: absolute;
//     top: -10px;
//     left: -95px;
// }

.reverce {
    transform: rotate(180deg);
}

.open-lang {
    height: 70px;
    transition: all .5s ease;

   
    .other-lang {
        opacity: 1;
        margin-top: 30px;
        cursor: pointer;
        transition: all .3s ease;
        pointer-events: all;
        color: #525C7A;

        &:hover {
            color: #438DF6;
        }
    }
}

.registration-buttons {
    margin: auto 0;

    @media (max-width: 1010px) {
        display: none;
    }
}

.come-in-button {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;  
    color: #2F3546;
    margin-right: 24px;
transition: all .3s ease;

&:hover {
color: #438DF6;
}

}

.registration-button {
    background: #2F3546;
box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.12);
border-radius: 2000px;
padding: 10px 24px;
font-weight: 600;
font-size: 16px;
line-height: 20px;
color: #FFFFFF;
}


.demo {
	margin: auto 0;

    @media (min-width: 1010px) {
        display: none;
    }
}


.menu-icon {
	position: relative;
	width: 50px;
	height: 50px;
	cursor: pointer;

	.menu-icon__cheeckbox {
		display: block;
		width: 100%;
		height: 100%;
		position: relative;
		cursor: pointer;
		z-index: 2;
		-webkit-touch-callout: none;
		opacity: 0;
	}
	div {
		margin: auto;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		width: 22px;
		height: 12px;
	}
	span {
		position: absolute;
		display: block;
		width: 100%;
		height: 2px;
		background-color: #516785;
		border-radius: 2px;
		transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);

		&:first-of-type {
			top: 0;
            width: 30px;
		}
		&:last-of-type {
			bottom: 0;
            right: -7px;
            width: 20px;
            height: 2px;
		}
	}
}

.menu-icon-active {
		span {
			&:first-of-type {
				transform: rotate(45deg);
				top: 6px;
                width: 22px;
                background-color: #4086F5;
               
			}
			&:last-of-type {
				transform: rotate(-45deg);
				bottom: 4px;
                left: 0;
                width: 22px;
                background-color: #4086F5;
			}
		}

	&.active:hover span:first-of-type,
	&.active:hover span:last-of-type {
		width: 22px;
	}  
}

.mobile-navigation {
    position: absolute;
    background-color: #FFFFFF;
    width: 100%;
    height: auto;
    top: 92px;
    left: 0;
    transform: translateX(-150%);
    transition: transform .5s ease;
    padding: 16px;
    z-index: 100;

    .mobile-navigation-list {
        .mobile-navigation-item {
            height: 68px;
            border-bottom: 1px solid #CED2DE;

            a {
                font-weight: 500;
            font-size: 16px;
            line-height: 65px; 
            color: #525C7A; 
            text-decoration: none;
            }
        }
    }

    .mobile-language-menu {
        display: flex;
    justify-content: center;
    margin-top: 50px;

        .language-menu-mobile-button {
            position: relative;
            background-color: transparent;
            border: none;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #525C7A;
            padding: 0 5px;

            &:nth-child(1) {
                border-right: 2px solid #525C7A;
            }
        }

        .language-menu-mobile-button.active {
          color: #438DF6;  
        }
    }
}

.menu_active {
    transform: translateX(0%);
  }