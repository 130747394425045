.about-container {
    @media (max-width: 500px) {
        padding-left: 15px;
        padding-right: 15px;
    }
    .about-container-team-work-first {
        display: flex;
        justify-content: space-between;

        @media (max-width: 1050px) {
            flex-direction: column;
        }

        .text-first-team-conttainer {
            width: 50%;

            @media (max-width: 1050px) {
                width: 100%;
                margin-bottom: 40px;
            }

            .who-we-are {
                font-weight: 600;
                font-size: 20px;
                line-height: 26px;
                color: #525C7A;
                margin-top: 40px;

                @media (min-width: 800px) and (max-width: 1050px) {
                   text-align: center;
                }

                @media (max-width: 500px) {
                    font-size: 16px;
                }
            }

            .about-us-title {
                font-weight: 600;
                font-size: 60px;
                line-height: 64px;
                color: #2F3546;
                margin-top: 20px;

                @media (min-width: 800px) and (max-width: 1050px) {
                    text-align: center;
                 }

                 @media (max-width: 500px) {
                    font-size: 36px;
                }
            }

            .about-us-text {
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                color: #525C7A;
                margin-top: 20px;
                max-width: 590px;

                @media (min-width: 800px) and (max-width: 1050px) {
                    text-align: center;
                    max-width: 70%;
                    margin: 20px auto 0 auto;
                 }
            }
        }

        .first-team-image {
            width: 50%;

            @media (max-width: 800px) {
                width: 90%;
             }
        }
    }

    .about-container-team-work-second {
        display: flex;
        justify-content: space-between;
        margin-top: -160px;

        @media (max-width: 1250px) {
            margin-top: -100px;
        }

        @media (max-width: 1150px) {
            margin-top: -70px;
        }

        @media (max-width: 1050px) {
            flex-direction: column;
            margin-top: 0;
        }

        .second-team-image {
            width: 50%;
            

            @media (min-width: 1051px) {
                margin-left: -30px;
            }


            @media (max-width: 1050px) {
                -webkit-box-ordinal-group: 3;
                -webkit-order: 2;
                    -ms-flex-order: 2;
                        order: 2;
            }

            @media (max-width: 800px) {
                width: 90%;
             }
           
        }

        .text-second-team-comtainer {
            width: 50%;
            padding: 260px 0 0 120px;
            background-image: url('../images/background/e-commerce-bg.png');
            background-size: 600px 800px;
            background-repeat: no-repeat;

            @media (max-width: 1050px) {
                padding: 70px 0 10px 0;
                width: 100%;
                margin: 0 auto;
                background-size: 600px 400px;
            }

            @media (max-width: 600px) {
                background-size: 400px 400px; 
            }
            @media (max-width: 400px) {
                background-size: 300px 400px; 
            }

            .about-us-title {
                font-weight: 600;
                font-size: 60px;
                line-height: 64px;
                color: #2F3546;
                margin-top: 20px;

                @media (min-width: 800px) and (max-width: 1050px) {
                    text-align: center;
                 }

                 @media (max-width: 500px) {
                    font-size: 36px;
                }
            }

            .about-us-text {
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                color: #525C7A;
                margin-top: 20px;
                max-width: 590px;

                @media (min-width: 800px) and (max-width: 1050px) {
                    text-align: center;
                    margin: 20px auto 0 auto;
                 }
            }

            .our-goals-list {
                list-style: none;
                margin-top: 36px;

                .our-goals-item {
                    max-width: 392px;
                    position: relative;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 24px;
                    color: #525C7A;
                    margin-top: 20px;
                    padding-left: 35px;

                    &::before {
                        content: url('../images/checkcircle.svg');
                        position: absolute;
                        top: 0;
                        left: 0;
                    }

                    @media (min-width: 800px) and (max-width: 1050px) {
                        margin: 20px auto 0 auto;
                        max-width: 60%;
                     }
                }
            }

            @media (max-width: 1050px) {
                -webkit-box-ordinal-group: 2;
                -webkit-order: 1;
                    -ms-flex-order: 1;
                        order: 1;
            }
        }
    }
}