.main-section {
    padding-top: 70px;

    @media (max-width: 800px) {
        padding-top: 48px;
    }

    @media (max-width: 400px) {
        padding-top: 20px;
    }


    .main-section-title {
        font-weight: 600;
        font-size: 60px;
        line-height: 64px;
        text-align: center;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        max-width: 740px;
        margin: 0 auto;

        @media (max-width: 800px) {
            font-weight: 600;
            font-size: 40px;
            line-height: 48px;
            text-shadow: none;
        }
    }

    .main-section-text {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px; 
        text-align: center;
        max-width: 390px;
        margin: 15px auto 40px auto ;

        @media (max-width: 800px) {
            font-size: 16px;
        }
    }

    .main-buttons-container {
        display: flex;
        justify-content: center;
        gap: 16px;

        .start-button {
            padding: 10px 24px;
            background: #2F3546;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.12);
            border-radius: 2000px;   
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            color: #FFFFFF;
            border: none;
            // margin-right: 16px;

            @media (max-width: 500px) {
                margin-right: 0;
                margin-bottom: 16px;
                width: 90%;
            }
        }
        
        .watch-wideo-button {
            display: flex;
            justify-content: center;
            background: #FFFFFF;
            border-radius: 2000px;
            border: none;
            padding: 10px 24px;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            color: #2F3546;

            @media (max-width: 500px) {
                width: 90%;
            }

            .main-menu-icon {
                vertical-align: bottom;
            }
        
        }

        @media (max-width: 500px) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    .main-section-image {
        max-width: 1300px;
    margin: 10px auto 0 auto;

    @media (max-width: 1300px) {
        width: 100%;
        margin: 30px auto 0 auto;
    }

    @media (max-width: 500px) {
        display: none;
    }
    }
    
}
.mobile-main-section-image {
    margin-top: 41px;
    @media (min-width: 501px) {
        display: none;
    }
}
