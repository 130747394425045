.our-team-container {
    padding-bottom: 110px;

    .backdrop-title {
        background-image: url('../images/background/question-bg.png');
        background-repeat: no-repeat;
        background-size: 800px 300px;
        background-position: left -20px top 0;
        padding: 100px 0 50px 0;

        .our-team-title {
            font-weight: 600;
            font-size: 60px;
            line-height: 64px;
            color: #2F3546;

            @media (min-width: 800px) and (max-width: 1050px) {
                text-align: center;
             }

            @media (max-width: 500px) {
                font-size: 36px;
            }
        }
    
        .our-team-description {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: #525C7A;
            max-width: 520px;
            margin-top: 16px;

            @media (min-width: 800px) and (max-width: 1050px) {
                margin: 16px auto 0 auto;
                text-align: center;
             }
        }   
    }
    .all-team-container {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        // justify-content: center;

        @media (max-width: 1280px) {
            justify-content: center;
        }

        .developer-container {
            padding: 32px;
            background: #F8F9FC;
            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04), 0px 2px 2px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.12);
            border-radius: 40px;
            width: 290px;
            margin: 24px 0;

            .developer-image {
                width: 112px;
                margin-bottom: 50px;
            }

            .developer-name {
                font-weight: 500;
                font-size: 24px;
                line-height: 26px;
                text-align: center;
                color: #000000;
            }

            .developer-position {
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                color: #525C7A;
                margin-top: 8px;
            }
        }
    }
}