    .questions-section-container {
        display: flex;
        height: 600px;
        margin-bottom: 60px;

        @media (max-width: 1250px) {
            flex-direction: column;
            align-items: center;
            height: auto;
        }

        .questions-title-container {
            width: 50%;
            background-image: url('../images/background/question-bg.png');
            background-size: 650px 450px;
            background-repeat: no-repeat;
            padding-top: 90px;


            @media (max-width: 1250px) {
                width: 100%;
            }

            .questions-title {
                font-weight: 600;
                font-size: 60px;
                line-height: 64px;  
                max-width: 480px;
                margin: 0 auto 32px auto;

                @media (max-width: 1250px) {
                    max-width: 680px;
                    text-align: center;
                }

                @media (max-width: 600px) {
                    font-size: 40px;
                }
            }
        }

        .questions-info-container {
            width: 50%;
            padding-top: 90px;

            @media (max-width: 1250px) {
                width: 100%;
            }

            .questions-list {
               .questions-items {
                border-bottom: 1px solid #CED2DE;
                height: 72px;
                cursor: pointer;
                transition: all .2s ease;

                &:hover {
                    padding: 10px;
                    background: #F9FAFB;
                    border-radius: 12px;
                }

                .item-view-container {
                    display: flex;
                    height: 100%;
                    transition: height .5s ease;

                    .questions-items-text {
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 38px;  
                        margin: auto 0;

                        @media (max-width: 600px) {
                            font-size: 16px;
                        }
                    }
    
                    .is-open-info {
                        font-weight: 400;
                        font-size: 30px;
                        color: #525c7abf;
                        margin-right: 20px;
                        margin: auto 20px auto 0;
                    }
                }

             
               } 
               .hidden-question-answer {
                display: none;
               }

               .open {
                height: auto;

                .item-view-container {
                    height: 40%;
                }
                .hidden-question-answer {
                    max-width: 580px;
                    margin: 15px 0 15px 0;
                    display: block;

                    @media (max-width: 600px) {
                        font-size: 16px;
                        padding: 5px;
                    }

                }
               }
            }
        }
    }
    // .hidden {
    //     display: block;
    // }
    .main-buttons-container {
        text-align: center;
        display: flex;
        justify-content: center;
        gap: 16px;
        margin: 0 auto;

        .start-button {
            padding: 10px 24px;
            background: #2F3546;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.12);
            border-radius: 2000px;   
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            color: #FFFFFF;
            border: none;
            // margin-right: 16px;
        }

        .question-button {
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.12);
        }
        
        .watch-wideo-button {
            display: flex;
            background: #FFFFFF;
            border-radius: 2000px;
            border: none;
            padding: 10px 24px;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            color: #2F3546;
            justify-content: center;

            .main-menu-icon {
                vertical-align: bottom;
            }
            p {
                margin-left: 5px;
            }
        
        }
    }

    .question-buttons-container {
        @media (max-width: 600px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;

            .start-button,
            .watch-wideo-button {
                width: 80%;
                margin-right: 0;
            }
        }
        

    }
