.help-customers-container {
    padding-bottom: 80px;  
}

.commerce-small-container {
    display: flex;
    height: 500px;
    width: 100%;
    

    @media (max-width: 1100px) {
        height: auto;
     }

     @media (max-width: 850px) {
        flex-direction: column;
        align-items: center;
     }

    //  &:not(:last-child) {
    //     margin-bottom: 80px;  
    //  }

    .mobile-container {
        @media (min-width: 851px) {
            display: none;
         }  
    }

   

    .text-container {
        width: 50%;
        background-size: 100%;

        @media (max-width: 850px) {
            display: flex;
             flex-direction: column;
            margin-bottom: 32px;
            width: 100%;
         }

        .commerce-section-title {
            font-weight: 600;
            font-size: 36px;
            line-height: 42px;
            max-width: 255px;
            margin-bottom: 16px;

            @media (max-width: 1400px) {
                font-size: 30px; 
            }

            @media (max-width: 850px) {
               text-align: center;
               margin: 0 auto;
             }
        }
        .commerce-section-text {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            max-width: 400px;
            @media (max-width: 1400px) {
                font-size: 16px; 
            }
            @media (max-width: 850px) {
                text-align: center;
                margin: 0 auto;
              }
        }

        .icon-commerce {
            margin-bottom: 32px;
            margin: 0 auto;
            margin-bottom: 15px;

            @media (max-width: 1100px) {
                margin-bottom: 0;
             }

             @media (max-width: 850px) {
                margin-bottom: 15px;
              }
        }
    }

    .text-commerce-container {
        background-image: url('../images/background/e-commerce-bg.png');
        padding: 37px 99px 99px 114px;
        background-repeat: no-repeat;

        @media (max-width: 1100px) {
           padding: 10px;
        }

        @media (max-width: 850px) {
            background-position: center;
            background-size: 600px 400px;
        }
    }
    .text-api-container {
        background-image: url('../images/background/api-bg.png');
        padding: 69px 99px 110px 146px;
        background-size: 650px 500px;
        background-repeat: no-repeat;
        background-position: center;

        @media (max-width: 1100px) {
            padding: 10px;
            background-size: 550px 500px;
         }
         @media (max-width: 850px) {
            background-size: 600px 400px;
        }
    }
    .commerce-image-container {
        width: 50%;

        @media (max-width: 850px) {
            width: 80%;
          }
          @media (max-width: 500px) {
            width: 100%;
          }

                  .commerce-image {
                      @media (max-width: 550px) {
                          display: none;
                      }
                  }
        
                  .commerce-image-mobile {
                      @media (min-width: 551px) {
                          display: none;
                      }
                  }
    }
}