.benefit-section {
    background-color: #F8F9FC;
    position: relative;
    padding-bottom: 64px;

    .benefits-container {
        overflow: hidden;
        position: relative;
        height: 1150px;
        transition: transform .5s ease;

        @media (max-width: 1000px) {
            height: 900px; 
        }

        @media (max-width: 1000px) {
            height: 900px; 
        }
        @media (max-width: 700px) {
            height: 2000px; 
        }
        @media (max-width: 600px) {
            height: 1900px;
         }
         @media (max-width: 500px) {
            height: 1800px;
         }
         @media (max-width: 400px) {
            height: 1700px;
         }
  
    }

    .mob-first-benefits-container {
        overflow: hidden;
        position: relative;
        height: 1090px;
        transition: transform .5s ease;  

        @media (max-width: 700px) {
            height: 1400px; 
        }

        @media (max-width: 500px) {
            height: 1300px;
         }
    }
}

.benefit-title-container {
    padding-top: 64px;

.benefit-bg {
    position: absolute;
    height: 250px;
    width: 800px;
    top: 0px;

    @media (max-width: 800px) {
        width: 80%;
    }
}

.benefit-title {
    font-weight: 600;
    font-size: 60px;
    line-height: 64px;
    text-align: center;
    color: #2F3546; 
    max-width: 870px; 
    margin: 0 auto;

    &::after {
        content: url('../images/styles/benefit-title.png');
        vertical-align: sub;
        margin-left: 15px;
    }

    @media (max-width: 600px) {
        font-size: 40px;
    }
}
}
.benefit-image {
    margin-left: 50px;
}

.benefit-image-second {
    width: 90%;
    margin: 0 auto;  
    margin-left: 90px;
}

.benefit-image-third {
    width: 80%;
    margin: 0 auto;
    margin-left: 100px;
}

.benefits-opportunities-list {
    display: flex;
    justify-content: center;
    padding-top: 64px;
    padding-bottom: 64px;
    transition: transform .5s ease;

    @media (max-width: 700px) {
       flex-direction: column;
       align-items: center;
       gap: 24px;
       padding-top: 1350px;
    }
    @media (max-width: 600px) {
        padding-top: 1300px;
     }
     @media (max-width: 500px) {
        padding-top: 1120px;
     }
     @media (max-width: 400px) {
        padding-top: 950px;
     }
}

.mob-benefits-opportunities-list {
    display: flex;
    justify-content: center;
    padding-top: 64px;
    padding-bottom: 64px;
    transition: transform .5s ease;

    @media (max-width: 700px) {
        flex-direction: column;
        align-items: center;
        gap: 24px;
        padding-top: 700px;
     }
     @media (max-width: 500px) {
        padding-top: 600px;
     }
}


.benefits-opportunities-item {
    width: 33.33333%;
    text-align: center;
    border-top: 3px solid #EDEEF3;
    padding: 16px 24px;
    cursor: pointer;
    transition: all .5s ease;
    color: #2F3546;

    &:hover {
                .benefits-opportunities-text {
                    color: #438DF6;

                   
                }
                 .text-buttons-hover {
                    color: #438DF6;
                 }
            
                .icon-opportunities {
                    margin-bottom: 16px;
                    fill: #438DF6;
                }
            
               
    }

    @media (max-width: 700px) {
       width: 100%;
     }

    .icon-opportunities {
        margin-bottom: 16px;
        transition: all .5s ease;
        fill: #2F3546;
    }

    .benefits-opportunities-text {
        font-weight: 600;
        font-size: 20px;
        line-height: 26px; 
        text-align: center;
        max-width: 365px;
        margin: 0 auto;
        transition: all .5s ease;
        color: #2F3546; 
    }
    span {
            font-weight: 400;
            font-size: 18px;
            transition: all .5s ease;
    }
    
        ;
}

.active {
    border-top: 3px solid #438DF6; 

    .benefits-opportunities-text {
        color: #438DF6;
    }
    .icon-opportunities {
        margin-bottom: 16px;
        transition: all .5s ease;
        fill: #438DF6;
    }
    span {
        color: #438DF6;
    }
}



.first-benefit {
    position: absolute;
    transform: translateX(0);  
    transition: transform 1s ease;
    @media (max-width: 700px) {
        display: none;
    }
}

.second-benefit {
    position: absolute;
    transition: transform 1s ease;
    transform: translateX(150%);
    @media (max-width: 700px) {
        display: none;
    }
}

.third-benefit {
    position: absolute;
    transition: transform 1s ease;
    transform: translateX(300%);
    @media (max-width: 700px) {
        display: none;
    }
}



.mob-first-benefit {
    position: absolute;
    transform: translateX(0);  
    transition: transform 1s ease;
    @media (min-width: 701px) {
        display: none;
    } 
}
.mob-second-benefit {
    position: absolute;
    transition: transform 1s ease;
    transform: translateX(150%);
    @media (min-width: 701px) {
        display: none;
    }
}
.mob-third-benefit {
    position: absolute;
    transition: transform 1s ease;
    transform: translateX(300%);
    @media (min-width: 701px) {
        display: none;
    }
}

.first-active {
    transform: translateX(0) !important;  
}

.second-active {
    transform: translateX(150%) !important;
}

.third-active {
    transform: translateX(150%) !important;
}


.benefits-info {
    max-width: 780px;
    margin: 0 auto;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
}

.benefit-mobile-image {
    margin-top: 80px;
}


.benefits-mobile-info {
    margin-top: 35px;
}