.ai-section-title {
        font-weight: 600;
            font-size: 60px;
            line-height: 64px;
            text-align: center;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            max-width: 1044px;
            margin: 0 auto;
        
            @media (max-width: 800px) {
                font-weight: 600;
                font-size: 40px;
                line-height: 48px;
                text-shadow: none;
            }

                                 @media (max-width: 500px) {
                                    font-size: 32px;
                                     max-width: 340px;
                                    line-height: 42px;
                                 }
}

.ai-section-text {
    font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        max-width: 707px;
        margin: 24px auto 32px auto;
    
        @media (max-width: 800px) {
            font-size: 16px;
        }

   
}

  .eng-ai-section-image {
      margin-top: 60px !important;
  }

.ai-mobile-main-section-image {
    margin-top: 11px;
        @media (min-width: 501px) {
            display: none;
        }
}


.third-ai-title {
    display: block;
    font-weight: 600;
    font-size: 60px;
    line-height: 64px;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: 64px;
    margin: 0 auto;   
    
    &::after {
            content: "";
            border-right: 3px solid #2f3546;
            animation: cursor 0.75s step-end infinite;
        }

    @media (max-width: 800px) {
            font-weight: 600;
            font-size: 40px;
            line-height: 48px;
            text-shadow: none;
        }
          @media (max-width: 500px) {
              font-size: 32px;
              line-height: 42px;
            height: 32px;
          }

          @media (max-width: 380px) {
            height: 50px;
          }
}


@keyframes cursor {
    0% {
        border-color: #2f3546;
    }

    50% {
        border-color: transparent;
    }

    100% {
        border-color: #2f3546;
    }
}

@keyframes typing {
    from {
        width: 0
    }
}