.creatives-cection {
    padding-top: 100px;
    padding-bottom: 50px;

    .creatives-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 26px;  
        text-align: center;

        @media (max-width: 500px) {
            display: none;
        }
    }

    .creatives-companies-list {
        display: flex;
        margin-top: 40px;

        // @media (max-width: 1200px) {
            justify-content: center;
            gap: 24px;
            flex-wrap: wrap; 
        // }
        @media (max-width: 400px) {
            gap: 0;
        }

        .icon {
            width: 120px;
            
            @media (max-width: 1200px) {
                width: 150px;
            } 
            @media (max-width: 400px) {
                width: 120px;
                margin-bottom: 24px;
            }
        }
    }
}

