.commerce-info-containers {
    .commerce-small-container {
       .opport-list-container{
        padding: 10px 50px 99px 50px;
       } 

    }
}


.opportunities-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 65px 0 110px 0;


    .opportunities-text {
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
        color: #525C7A;
    }

    .opportunities-title {
        font-weight: 600;
        font-size: 60px;
        line-height: 64px;
        text-align: center;
        color: #2F3546;
        max-width: 680px;
    }
}

.commerce-small-container {
    &:not(:last-child) {
        margin-bottom: 80px;  
     }
}

.second {
    margin-top: 16px !important;
}

.commerce-section-title-opp {
    max-width: 420px !important;
}

.icon-opp {
    stroke: #41416E;
    vertical-align: bottom;
    margin: 0;
}

.opp-list {
    @media (max-width: 850px) {
        margin: 0 auto;
        margin-top: 16px;
    }
    li {
        margin-bottom: 5px;
    }
}

.commerce-image-container {
    // display: flex;

    // .commerce-image {
    //     margin: auto 0;
    // }
        .commerce-image {
            @media (max-width: 550px) {
                display: none;
            }
        }
    
        .commerce-image-mobile {
            @media (min-width: 551px) {
                display: none;
            }
        }
}