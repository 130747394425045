.seo-main-container {
  padding: 66px 10px 66px 10px;  

  .show-container {
    position: relative;
    height: 250px;
    overflow: hidden;
    transition: all 1s linear;
  }

  .gradient-bottom::before {
    content:"";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 55px;
    background: linear-gradient(to top, #fff, transparent); 
  }

  .opening {
    height: auto;
  }

  .show-more-button {
    display: block;
    margin: 10px 0 0 0;
    background-color: transparent;
    border: none;
    color: #438DF6;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;

            &::after {
                content: '➜';
                color: #438df6;
                  font-size: 15px;
                  font-weight: 600;
                  line-height: 15px;
                  margin-left: -2px;
                  vertical-align: -1px;
                  opacity: 0;
                  transition: all .2s ease;
              }
            
              &:hover::after {
                  margin-left: 5px;
                  opacity: 1;
              }
  }
}

.seo-inform-container {
    &:not(:last-child) {
        margin-bottom: 66px;
    }


    .seo-inform-title {
        font-weight: 600;
        font-size: 28px;
        line-height: 36px;  
        color: #2F3546;
        margin-bottom: 16px;
    }

    .seo-inform-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #525C7A; 
        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    .title-inform {
        font-weight: 600;
    }

    .seo-inform-list {
      .seo-inform-items {
        position: relative;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #525C7A;
        padding-left: 15px;
        &:not(:last-child) {
            margin-bottom: 4px;
        }
        &::before {
            position: absolute;
            content: '.';
            bottom: 5px;
            left: 5px;
        }
      }  
    }
}