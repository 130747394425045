@import '~node_modules/modern-normalize/modern-normalize.css';

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-bottom: 0;
}

a {
  text-decoration: none;
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

button {
  cursor: pointer;
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.link {
  text-decoration: none;
  color: #438DF6;  
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  color: #2F3546;
  letter-spacing: -0.3px;
}

.disabled {
  pointer-events: none;
  cursor: default;
  color: #888 !important;
  background-color: rgb(217, 217, 217) !important;
}

html {
  scroll-behavior: smooth;
}

.black-hov {
 transition: all .5s ease;

  &:hover {
    color: #fff !important;
    background: #438DF6 !important;
    box-shadow: 0px 0px 1px 3px rgba(67, 141, 246, 0.6), 0px 0px 2px 1px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.15) !important;
  }
}



.white-hov {
   transition: all .5s ease;

  p {
    transition: all .5s ease;
  }

  &:hover {
    color: #438DF6 !important;
    fill: #438DF6 !important;

    p {
      color: #438DF6 !important;
    }
   box-shadow: 0px 0px 1px 3px rgba(67, 141, 246, 0.6), 0px 0px 2px 1px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.15) !important;
  }
}