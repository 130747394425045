.ai-benefits-container {
    background-image: url('../images/background/benefit-big-bg.png');
    padding-top: 100px;
    padding-bottom: 95px;

    @media (max-width: 700px) {
            background-repeat: no-repeat;
            background-position: 0% 18%;
            padding-top: 50px;
    }
}

.ai-benefits-title {
            font-weight: 600;
                font-size: 60px;
                line-height: 64px;
                text-align: center;
                margin: auto;
                margin-bottom: 70px;
            
                @media (max-width: 1000px) {
                    font-size: 40px;
                    width: 80%;
                }

                @media (max-width: 950px) {
                   margin-bottom: 20px;
                }
            
                @media (max-width: 400px) {
                    font-size: 30px;
                    line-height: 38px;
                    width: 100%;
                    margin-bottom: 20px;
                }
}

.ai-choise-container {
        display: flex;
        justify-content: center;
        gap: 24px;
        
            @media (max-width: 950px) {
                flex-direction: column;
                align-items: center;
            }

           
        
            .ai-choise {
                width: 444px;
                background: #FFFFFF;
                box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04), 0px 2px 2px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.12);
                border-radius: 32px;
                transition: all .5s ease;
                transition: all .5s ease;

                @media (max-width: 500px) {
                        width: 90%;
                    }
        
                &:hover {
                    box-shadow: 0px 0px 1px 3px rgba(67, 141, 246, 0.6), 0px 0px 8px rgba(0, 0, 0, 0.04), 0px 2px 2px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.12);
                }

        
                .main-ai-info {
                    padding: 32px;
                    border-bottom: 1px solid #CED2DE;
                    display: flex;
                    flex-direction: column;

                    .icon-benefits-ai {
                        margin: 0 auto 10px auto;
                    }

                    .ai-benefits-text {
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 30px;
                        text-align: center;
                        color: #2F3546;

                        .ai-benefits-bold {
                            font-size: 26px;
                            font-weight: 900;
                            color: #222630;
                        }
                    }
                }

               .positive {
                stroke: #28C76F ;
               }

               .negative {
                stroke: #EA5455 ;
               }

               .benefits-tariff-item {
                display: flex;
               }
        
            }
}

.span-benefits {
    font-weight: 900;
    font-size: 15px;
}

.ua-benefits-title {
    @media (max-width: 400px) {
        max-width: 330px;
    }
}

.ai-benefits-buttons-container {
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04), 0px 2px 2px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    margin: 0 auto;
    width: 444px;
    padding: 4px 10px;
    height: 50px;
    margin-bottom: 8px;
    display: flex;

    @media (min-width: 950px) {
        display: none;
    }

    @media (max-width: 500px) {
        width: 90%;
    }

    .ai-benefits-buttons {
        border: none;
        background: transparent;
        width: 50%;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #2F3546;
    }

    .first {
        border-right: 1px solid #DAE6FB;
    }
}

.opacity-button {
    opacity: 0.5;
}

.opacity-container {
display: block !important;
}

.opacity-container-show {
@media (max-width: 950px) {
        display: none;
    }
}