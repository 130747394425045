.tariff-main-title-container {
    display: flex;
    flex-direction: column;
    align-items: center;


    .name-page {
        text-align: center;
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 16px;
        padding-top: 65px;
        color: #525C7A;
    }   
    .tariff-main-title {
        font-weight: 600;
        font-size: 60px;
        line-height: 64px;
        text-align: center;
        margin-bottom: 32px;
        max-width: 680px;

        @media (max-width: 500px) {
            font-size: 36px;  
            line-height: 42px;
        }
    }

    .safe-proposition {  
background: rgba(255, 255, 255, 0.7);
backdrop-filter: blur(20px);
border-radius: 100px;
margin-bottom: 16px;
padding: 10px 22px;
display: flex;

p {
  font-weight: 500;
font-size: 14px;
line-height: 17px;
color: #438DF6;
margin-left: 10px;
}
    }
}


.switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 24px;
    // margin-right: 180px;
  }
  
  .switch input {
    display: none;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: -3px;
    bottom: 0;
    background: rgba(158, 195, 247, 0.5);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    transition: all .5s ease;

    &:hover {
      box-shadow: 0px 0px 0px 4px rgb(67 141 246 / 50%);
    }
  }
  
  .slider:before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 10px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  input:checked + .slider {
    background-color: #525C7A;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #ccc;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(27px);
    -ms-transform: translateX(27px);
    transform: translateX(27px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .checkbox-container {
    display: flex;
    gap: 20px;

    @media (max-width: 400px) {
      gap: 10px;
    }
  }

  .text-checkbox {
    // width: 230px;
    // padding-left: 65px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #525C7A;

    span {
        font-weight: 600;
        color: #000000;
    }
  }
  .tariff-choise-contsiner {
    display: flex;
    justify-content: center;
    gap: 24px;
    padding-top: 110px;

    @media (max-width: 950px) {
     flex-direction: column;
     align-items: center;   
    }

    .tariff-choise {
        width: 395px;
        background: #FFFFFF;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04), 0px 2px 2px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.12);
        border-radius: 32px;
        transition: all .5s ease;
        transition: all .5s ease;

        &:hover {
          box-shadow: 0px 0px 1px 3px rgba(67, 141, 246, 0.6), 0px 0px 8px rgba(0, 0, 0, 0.04), 0px 2px 2px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.12);
        }
        
        .main-tariff-info {
        padding: 32px; 
          border-bottom: 1px solid #CED2DE;
        }

        @media (max-width: 400px) {
            width: 100%; 
           }
    }
  }

  .favorite-tariff-container {
    display: flex;
    justify-content: space-between;

    .favorite-tariff {
       width: 76px;
       height: 32px; 
       background: #CED2DE;
       border-radius: 2000px;
       font-weight: 500;
        font-size: 14px;
        line-height: 30px;
        text-align: center;
        color: #2F3546;
    }
  }

  .tariff-name {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #2F3546;
    margin-bottom: 32px;
    transition: all .5s ease;
  }

  .time-tariff {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #525C7A;
    margin-bottom: 4px;
    transition: all .5s ease;
  }

  .price-tariff {
    font-weight: 500;
    font-size: 60px;
    line-height: 64px;
    color: #2F3546;
    margin-bottom: 32px;
    transition: all .5s ease;
  }

  .buy-tariff-button {
    border: none;
    width: 100%;
    height: 40px;
    background: #2F3546;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 2000px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    transition: all .5s ease;

    &:hover {
      
    }
  }
  .benefits-tariff-list {
    padding: 32px;

    .benefits-tariff-item {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #2F3546; 
        

        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }
   .icon-benefits-tariff {
    vertical-align: middle;
    stroke: #525c7a;
    margin-right: 8px;
   } 
  }

  .active-tariff {
    background: #2F3546 !important;
box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04), 0px 2px 2px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.12) !important;
    .main-tariff-info {
        .tariff-name,
        .price-tariff {
            color: #FFFFFF;
        }

        .time-tariff {
            color: #989EAE;  
        }

        .buy-tariff-button {
            background: #FFFFFF; 
            color: #2F3546;
        }
    }
    .benefits-tariff-item {
        color: #FFFFFF;
    }
    .icon-benefits-tariff {
        stroke: #989EAE;
       } 
}