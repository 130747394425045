    .examples-container {
        display: flex;
        // flex-wrap: wrap;
        justify-content: center;
        gap: 24px;

        @media (max-width: 1260px) {
            margin-top: 28px;
            gap: 28px;
        }

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: center;
        }

        .example-card {
            max-width: 600px;
            height: 625px;
            border-radius: 40px;
            background-color: #F8F9FC;

            @media (max-width: 768px) {
                height: 430px;
            }


            .example-card-images {
                border-radius: 40px 40px 0 0;
                @media (max-width: 768px) {
                    width: 340px;
                    height: auto;
                }

            }

            .example-card-info {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 30px 32px;

                .text-example {
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 30px;
                    text-align: center;
                    margin-top: 16px;
                    color: #2F3546;

                    @media (max-width: 768px) {
                        font-size: 20px;
                    }
                }
            }
        }
    }

           .ai-title {
               font-weight: 600;
               font-size: 60px;
               line-height: 64px;
               text-align: center;
               max-width: 875px;
               margin: auto;
               margin-bottom: 70px;
    
               @media (max-width: 1000px) {
                   font-size: 40px;
                   width: 80%;
               }
    
               @media (max-width: 400px) {
                   width: 100%;
               }
           }